/* eslint-disable no-unused-vars */
const initialState = {
  viewPersonalData: null,
  userData: null,
  personalData: [],
  userToken: null,
}

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VIEW_PERSONAL_DATA':
      return {
        ...state,
        viewPersonalData: action.data
      }
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: action.userData,
      }
    case 'SET_PERSONAL_DATA':
      return {
        ...state,
        personalData: action.personalData,
      }
    case 'SET_USER_TOKEN':
      return {
        ...state,
        userToken: action.userToken,
      }
    default:
      return state
  }
}

export default dataReducer;