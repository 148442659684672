/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { personalData, insertPersonalData, updatePersonalData, updatePersonalDataByDH, viewPersonalData, fetchData } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
import StarRatings from 'react-star-ratings';
import StarRatingComponent from 'react-star-rating-component';
import './Data.css'
import ClipLoader from "react-spinners/ClipLoader";

const getCurrentYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1

    // Determine the quarter based on the current month
    const currentQuarter = Math.ceil(currentMonth / 3);
    // If it's the first quarter, subtract 1 from the current year
    const year = currentQuarter === 1 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

    return year;
}

class EmployeeDetail extends Component {


    state = {
        userData: this.props.userData,
        loader: false,
        personalData: {
            id: '',
            emp_code: '',
            name: '',
            department: '',
            designation: '',
        },
        viewPersonalData: {
            id: '',
            emp_id: '',
            emp_code: '',
            emp_name: '',
            human_resource: '[]',
            hr_performance: 0,
            direct_manager_evaluation: '[]',
            dra_performance: 0,
            department_head_evaluation: '[]',
            dhe_performance: 0,
            overall_performance: 0,
            year: '',
            quarter: '',
        },

        // HR Evaluation variables
        hr_form: [
            { particulars: "Late", summary: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Half Days", summary: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Non-Approved Off Days", summary: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Approved Off Days", summary: 0, score: 0, remark: "", performance: 0 },
        ],

        // Direct Manager Evaluation variables
        dra_form: [
            { particulars: "Delivery of Task", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Efficiency in task", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Communication", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "New Skills / Technical Skills", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Independent", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Team Work", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Integrity", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Co-workers and Peer relation", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Dependability", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Relation with CX", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Teambuilding Outdoor Activities", rating: 0, score: 0, remark: "", performance: 0 },

        ],

        // Department Head Evaluation variables
        dhe_form: [
            { particulars: "Employess overall performance", rating: 0, score: 0, remark: "", performance: 0 },
        ],

        hr_overAllPerformance: 0,
        dra_overAllPerformance: 0,
        dhe_overAllPerformance: 0,
        kpi_performance: 0,

        // selectedYearQuarter: { 'year': null, 'quarter': null },
        selectedQuarter: null,
        // selectedQuarter: { 'quarter': null },
        currentYear: getCurrentYear(),
        // currentYear: new Date().getFullYear(),
        // selectedYear: { 'year': null }
        currentDate: new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear(),
        adminEmployeeData: null

    }

    default_remarks = [
        { rating: 1, remark: "Needs Action", performance: 20, score: 1 },
        { rating: 2, remark: "Below Expectations", performance: 40, score: 2 }, { rating: 3, remark: " Successfully Achieved", performance: 60, score: 3 },
        { rating: 5, remark: "Exceeds Expectations", performance: 80, score: 4 }, { rating: 4, remark: "Exceptional", performance: 100, score: 5 }
    ];
    // year = Array((new Date().getUTCFullYear()) - ((new Date().getUTCFullYear()) - 10)).fill('').map((v, idx) => (new Date().getUTCFullYear()) - idx);
    // quarter = ["Q1-(JAN-APR)", "Q2-(MAY-AUGUST)", "Q3-(SEPTEMBER-DECEMBER)"];
    quarter = ["JAN-JUNE", "JULY-DECEMBER"];

    componentDidMount = () => {
        if (this.props.location.data)
            this.setState({ personalData: this.props.location.data }, () => {
                if (this.state.userData.role === 'A') {
                    this.adminDisplayData()
                }
                else
                    this.displayData()
            })
        else
            this.props.history.replace({ pathname: `/list` })
    }

    adminDisplayData = () => {
        if ((this.props.fetchData).length <= 0) {

            this.props.fetchData(this.state.personalData.emp_code).then((data) => {
                if (data[0])
                    this.setState({ adminEmployeeData: data })
                // this.setState({
                //     viewPersonalData: data[0],
                //     hr_overAllPerformance: data[0].hr_performance,
                //     dra_overAllPerformance: data[0].dra_performance,
                //     dra_form: JSON.parse(data[0].direct_manager_evaluation),
                //     dhe_overAllPerformance: data[0].dhe_performance,
                //     dhe_form: JSON.parse(data[0].department_head_evaluation),
                //     kpi_performance: data[0].overall_performance
                // })
            })
        }
    }

    // After HR submit form then 
    displayData = () => {
        this.props.fetchData(this.state.personalData.emp_code).then((data) => {
            if (data[0])
                this.setState({
                    viewPersonalData: data[0],
                    hr_overAllPerformance: data[0].hr_performance,
                    dra_overAllPerformance: data[0].dra_performance,
                    dra_form: JSON.parse(data[0].direct_manager_evaluation),
                    dhe_overAllPerformance: data[0].dhe_performance,
                    dhe_form: JSON.parse(data[0].department_head_evaluation),
                    kpi_performance: data[0].overall_performance
                })
        })
    }

    // HR Evaluation variabels
    hr_change = (event, index, inputType) => {
        let chk = {
            "Late": [4, 8, 12, 24],
            "Half Days": [4, 8, 12, 16],
            "Non-Approved Off Days": [3, 6, 9, 12]
        };
        let tempHrForm = [...this.state.hr_form];
        if (event.target.value && index !== 3) {
            let score = 0;

            if (event.target.value <= chk[inputType][0]) {
                score = 5;
                tempHrForm[index] = Object.assign(tempHrForm[index], this.calc_score(score))
            } else if (event.target.value <= chk[inputType][1]) {
                score = 4;
                tempHrForm[index] = Object.assign(tempHrForm[index], this.calc_score(score))
            }
            else if (event.target.value <= chk[inputType][2]) {
                score = 3;
                tempHrForm[index] = Object.assign(tempHrForm[index], this.calc_score(score))
            }
            else if (event.target.value <= chk[inputType][3]) {
                score = 2;
                tempHrForm[index] = Object.assign(tempHrForm[index], this.calc_score(score))
            }
            else {
                score = 1;
                tempHrForm[index] = Object.assign(tempHrForm[index], this.calc_score(score))
            }
        }
        tempHrForm[index].summary = event.target.value;

        this.setState({ hr_form: tempHrForm, hr_overAllPerformance: this.calcFormPerformance(this.state.hr_form, "hr") }, () => this.setState({ kpi_performance: this.calc_kpi_performance() }));

    }

    // Direct Manager Evaluation variables
    dra_change = (value, index) => {
        let tempDraForm = [...this.state.dra_form];
        tempDraForm[index] = Object.assign({}, tempDraForm[index], this.calc_score(value))
        tempDraForm[index].rating = value;
        this.setState({ dra_form: tempDraForm, dra_overAllPerformance: this.calcFormPerformance(tempDraForm, "dra"), }, () => this.setState({ kpi_performance: this.calc_kpi_performance() }));
    }

    // Department Head Evaluation variables
    dhe_change = (value, index) => {
        let tempDheForm = [...this.state.dhe_form];
        tempDheForm[index] = Object.assign({}, tempDheForm[index], this.calc_score(value))
        tempDheForm[index].rating = value;
        this.setState({ dhe_form: tempDheForm, dhe_overAllPerformance: value }, () => this.setState({ kpi_performance: this.calc_kpi_performance() }));
    }

    quarter_change = (event, isYear) => {
        let temp = { ...this.state.selectedQuarter }
        temp.quarter = event.target.value
        if ((this.props.fetchData).length > 1) {
            let selectedValue = this.state.adminEmployeeData.filter(val => val.quarter === event.target.value)
            if (selectedValue.length > 0) {
                this.setState({
                    viewPersonalData: selectedValue[0],
                    hr_overAllPerformance: selectedValue[0].hr_performance,
                    dra_overAllPerformance: selectedValue[0].dra_performance,
                    dra_form: JSON.parse(selectedValue[0].direct_manager_evaluation),
                    dhe_overAllPerformance: selectedValue[0].dhe_performance,
                    dhe_form: JSON.parse(selectedValue[0].department_head_evaluation),
                    kpi_performance: selectedValue[0].overall_performance
                })
            } else {
                this.setState({
                    hr_form: [
                        { particulars: "Late", summary: 0, score: 0, remark: "", performance: 0 },
                        { particulars: "Half Days", summary: 0, score: 0, remark: "", performance: 0 },
                        { particulars: "Non-Approved Off Days", summary: 0, score: 0, remark: "", performance: 0 },
                        { particulars: "Approved Off Days", summary: 0, score: 0, remark: "", performance: 0 },
                    ],
                    // hr_form: '[]' ,
                    hr_overAllPerformance: 0,
                    dra_overAllPerformance: 0,
                    dra_form: '[]',
                    dhe_overAllPerformance: 0,
                    dhe_form: '[]',
                    kpi_performance: 0
                })
            }
        }
        // isYear ? temp.year = event.target.value
        //     : temp.quarter = event.target.value

        this.setState({ selectedQuarter: temp.quarter })

    }

    // Overall KPI Performance
    calc_kpi_performance = () => {
        return this.state.hr_overAllPerformance + this.state.dra_overAllPerformance + this.state.dhe_overAllPerformance
    }

    // Score Calculation 
    calc_score = (score) => {
        let calcScore = null;

        switch (score) {
            case 5:
                calcScore = this.default_remarks[score - 1];
                break;
            case 4:
                calcScore = this.default_remarks[score - 1];
                break;
            case 3:
                calcScore = this.default_remarks[score - 1];
                break;
            case 2:
                calcScore = this.default_remarks[score - 1];
                break;
            case 1:
                calcScore = this.default_remarks[score - 1];
                break;

            default:
                calcScore = this.default_remarks[score - 1];
                break;
        }

        return calcScore;
    }

    // KPI final Calculation (outof 25%)
    calcFormPerformance = (form, type) => {
        let tempPerformance = 0;
        form.map((item) => {
            tempPerformance += item.score;
        })
        let performance = ((tempPerformance / ((type === "hr" ? form.length - 1 : form.length) * 5)) * 10);

        return performance
    }

    // Insert KPI Data to DB
    insertData = () => {
        if (!this.state.selectedQuarter || this.state.selectedQuarter === 'Select Quarter') {
            alert('Please Select Quarter');
            return;
        }

        this.setState({ loader: true })
        const data = {
            emp_id: this.state.personalData.id,
            emp_code: this.state.personalData.emp_code,
            emp_name: this.state.personalData.name,
            hr_form: JSON.stringify(this.state.hr_form),
            hr_overAllPerformance: this.state.hr_overAllPerformance,
            dra_form: JSON.stringify(this.state.dra_form),
            dra_overAllPerformance: this.state.dra_overAllPerformance,
            dhe_form: JSON.stringify(this.state.dhe_form),
            dhe_overAllPerformance: this.state.dhe_overAllPerformance,
            kpi_performance: this.state.kpi_performance,
            selectedQuarter: this.state.selectedQuarter,
            currentYear: this.state.currentYear,
        };

        this.props.insertPersonalData(data).then((data) => {
            this.setState({ loader: false })
            if (data.err) {
                alert("Alert", data.err)
            } else {
                this.props.history.push({ pathname: `/list` })

            }
        });
    };

    updateData = () => {
        this.setState({ loader: true })
        const data = {
            id: this.state.viewPersonalData.id,
            emp_id: this.state.personalData.id,
            dra_form: JSON.stringify(this.state.dra_form),
            dra_overAllPerformance: this.state.dra_overAllPerformance,
            dhe_form: JSON.stringify(this.state.dhe_form),
            dhe_overAllPerformance: this.state.dhe_overAllPerformance,
            kpi_performance: this.state.kpi_performance,
        };
        this.props.updatePersonalData(data).then((data) => {
            this.setState({ loader: false })

            if (data.err) {
                alert("Alert", data.err)
            } else {
                this.props.history.push({ pathname: `/list` })

            }
        });
    }

    updateDataHead = () => {
        this.setState({ loader: true })
        const data = {
            id: this.state.viewPersonalData.id,
            emp_id: this.state.personalData.id,
            dhe_form: JSON.stringify(this.state.dhe_form),
            dhe_overAllPerformance: this.state.dhe_overAllPerformance,
            kpi_performance: this.state.kpi_performance,
        };
        this.props.updatePersonalDataByDH(data).then((data) => {
            this.setState({ loader: false })
            if (data.err) {
                alert("Alert", data.err)
            } else {
                this.props.history.push({ pathname: `/list` })

            }
        });
    }


    render() {
        const { hr_form, dra_form, dhe_form, hr_overAllPerformance, dra_overAllPerformance, dhe_overAllPerformance, kpi_performance } = this.state;
        return (
            <div className="container home">
                <div className="hr"></div>
                <h1 id='title'><b>Insert <u>{this.state.personalData.name}'s</u> KPI Detail</b></h1>

                {/*============================================== DETAILS ============================================================  */}
                <div id="p_Data" className='col-12'>
                    <div className="col-8">
                        <div>Employee Code: <b> {this.state.personalData.emp_code} </b></div>
                        <div>Name: <b>{this.state.personalData.name} </b></div>
                        <div>Employment Status: <b>{this.state.personalData.emp_status} </b></div>
                        <div>Department: <b> {this.state.personalData.department} </b></div>
                        {/* <div>Designation: <b> {this.state.personalData.designation} </b></div> */}
                        <div>Date: <b> {this.state.currentDate}</b></div>
                        <div> Year: <b> {this.state.currentYear} </b>
                            {/* <select onChange={(event) => this.quarter_change(event, true)} className="browser-default custom-select">
                                <option selected>Year</option>
                                {this.year.map((item, index) => (
                                    <option value={item}> {item} </option>
                                ))}
                            </select> */}
                        </div>
                        <div className="col col-4 quarter">
                            {(this.state.viewPersonalData.quarter).length <= 0 || this.state.userData.role === "A" ?
                                <select onChange={(event) => this.quarter_change(event, false)} className="browser-default custom-select">Select Quarter:
                                    <option key={-1} value={null} required>Select Quarter</option>
                                    {this.quarter.map((item, index) => (
                                        <option key={index} value={item}> {item} </option>
                                    ))}
                                </select>
                                :
                                <>
                                    Quarter: <b>{this.state.viewPersonalData.quarter}</b>
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className="hr"></div>

                {/*============================================== CRITERIA ============================================================  */}
                <h3 id='title'><b>Criteria</b></h3>
                <div id='container' className='col-12'>
                    <div className="row">
                        <div className="col-sm-8">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr className="bg-warning text-center">
                                        <th colSpan="2">
                                            Employee Monthly Evaluation Criteria:
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-right">1</td>
                                        <td className="text-left">Human Resource</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">2</td>
                                        <td className="text-left">Direct Manager</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">3</td>
                                        <td className="text-left">Department Head</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-4">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr className="bg-warning text-center">
                                        <th colSpan="2">
                                            Bonus Break up (4 Months)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-right">HR (Attendance)</td>
                                        <td className="text-left">10%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">DM (Individual Performance)</td>
                                        <td className="text-left">10%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right">Dept. Head</td>
                                        <td className="text-left">5%</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right"><b>Overall</b></td>
                                        <td className="text-right"><b>25%</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="hr"></div>

                {/*============================================== PARAMETERS ============================================================  */}
                <h3 id='title'><b>Parameters for Performance</b></h3>
                <div id='container' className='col-12'>
                    <div className="row">
                        <div className="col-sm-12">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        {/* <td className="text-right">1- </td> */}
                                        <td className="text-left">100% <StarRatings
                                            starDimension="20px"
                                            starSpacing="0px"
                                            starRatedColor="#ffff00"
                                            starHoverColor="#ffff00"
                                            starEmptyColor="#A9A9A9"
                                            rating={5}
                                            numberOfStars={5}
                                        // name='rating'
                                        /> as <b>Exceptional</b></td>
                                    </tr>
                                    <tr>
                                        {/* <td className="text-right">2- </td> */}
                                        <td className="text-left">80% <StarRatings
                                            starDimension="20px"
                                            starSpacing="0px"
                                            starRatedColor="#ffff00"
                                            starHoverColor="#ffff00"
                                            starEmptyColor="#A9A9A9"
                                            rating={4}
                                            numberOfStars={5}
                                        // name='rating'
                                        /> as <b>Exceeds Expectations</b></td>
                                    </tr>
                                    <tr>
                                        {/* <td className="text-right">3- </td> */}
                                        <td className="text-left">60% <StarRatings
                                            starDimension="20px"
                                            starSpacing="0px"
                                            starRatedColor="#ffff00"
                                            starHoverColor="#ffff00"
                                            starEmptyColor="#A9A9A9"
                                            rating={3}
                                            numberOfStars={5}
                                        // name='rating'
                                        /> as <b>Successfully Achieved</b></td>
                                    </tr>
                                    <tr>
                                        {/* <td className="text-right">4- </td> */}
                                        <td className="text-left">40% <StarRatings
                                            starDimension="20px"
                                            starSpacing="0px"
                                            starRatedColor="#ffff00"
                                            starHoverColor="#ffff00"
                                            starEmptyColor="#A9A9A9"
                                            rating={2}
                                            numberOfStars={5}
                                        // name='rating'
                                        /> as <b>Below Expectations</b></td>
                                    </tr>
                                    <tr>
                                        {/* <td className="text-right">5- </td> */}
                                        <td className="text-left">20% <StarRatings
                                            starDimension="20px"
                                            starSpacing="0px"
                                            starRatedColor="#ffff00"
                                            starHoverColor="#ffff00"
                                            starEmptyColor="#A9A9A9"
                                            rating={1}
                                            numberOfStars={5}
                                        // name='rating'
                                        /> as <b>Needs Action</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="hr"></div>

                {/*============================================== HR ============================================================  */}
                <h3 id='title'><b>Human Resource Evaluation</b></h3>
                {/* {this.state.userData.role === "DRA" && */}
                <>
                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Sumary</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Performance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hr_form.map((item, index) => (
                                <tr key={index}>
                                    <th scope="col"> {index + 1} </th>
                                    <th scope="col"> {item.particulars} </th>

                                    {JSON.parse(this.state.viewPersonalData.human_resource).length <= 0 ?
                                        <>
                                            <th scope="col"> <input className="form-control input" value={item.summary} type="number" id={item.particulars} onChange={(event) => this.hr_change(event, index, item.particulars)} ></input> </th>
                                            <th scope="col"> {item.remark} </th>
                                            <th scope="col"> {`${index !== 3 ? item.performance + "%" : ""}`}</th>
                                        </>
                                        :
                                        <>
                                            <th scope="col"> <input className="form-control input" value={JSON.parse(this.state.viewPersonalData.human_resource)[index].summary} type="number" id={item.particulars}></input> </th>
                                            <th scope="col"> {JSON.parse(this.state.viewPersonalData.human_resource)[index].remark} </th>
                                            <th scope="col"> {JSON.parse(this.state.viewPersonalData.human_resource)[index].performance}% </th>
                                        </>
                                    }


                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table id='overall'>
                        <tbody>
                            <tr className="float-right">
                                <th className="col">{` 0verall Attendance Avg.   ${hr_overAllPerformance.toFixed(2)}%`} </th>
                            </tr>
                        </tbody>
                    </table>
                </>
                {/* } */}

                <div className="hr"></div>

                {/* ===================================== DRA ============================================================== */}
                {this.state.userData.role !== "A" &&
                    <>
                        <div className="hr"></div>
                        <h3 id='title'>Direct Manager Evaluation</h3>
                        <table id='employee' className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Particulars</th>
                                    <th scope="col">Score</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Performance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dra_form.map((singleData, index) => (
                                    <tr key={index}>
                                        <th scope="col"> {index + 1} </th>
                                        <th scope="col"> {singleData.particulars} </th>
                                        {(JSON.parse(this.state.viewPersonalData.direct_manager_evaluation).length > 0) ?
                                            (JSON.parse(this.state.viewPersonalData.direct_manager_evaluation)[0].rating <= 0 || this.state.userData.role !== 'A') ?
                                                <>
                                                    {/* <th scope="col">  <StarRatingComponent
                                                        // starDimension="30px"
                                                        starColor="#ffff00"
                                                        // starHoverColor="#ffff00"
                                                        emptyStarColor="#808080"
                                                        // startDimension="30px"
                                                        // starHoverText="ASD"
                                                        // onStarHover={singleData.score = 5 && "ASD"}
                                                        onStarClick={(value) => this.dra_change(value, index)}
                                                        value={singleData.rating}
                                                        starCount={5}
                                                    // name='rating'
                                                    /> </th> */}
                                                    <th scope="col">  <StarRatings
                                                        starDimension="30px"
                                                        starRatedColor="#ffff00"
                                                        starHoverColor="#ffff00"
                                                        starEmptyColor="#A9A9A9"
                                                        changeRating={(value) => this.dra_change(value, index)}
                                                        rating={singleData.rating}
                                                        numberOfStars={5}
                                                        // pointer-events = "none"
                                                        // ignoreInlineStyles={false}
                                                        style={{ pointerEvents: "none" }}
                                                    // name='rating'
                                                    /> </th>
                                                    <th scope="col">{singleData.remark} </th>
                                                    <th scope="col"> {singleData.performance}% </th>
                                                </>
                                                :
                                                <>
                                                    <th scope="col"> <StarRatingComponent
                                                        // starDimension="30px"
                                                        starColor="#ffff00"
                                                        // starHoverColor="#ffff00"
                                                        emptyStarColor="#808080"
                                                        // starHoverText="ASD"
                                                        // changeRating={(value) => { this.dra_change(value, index)}}
                                                        value={JSON.parse(this.state.viewPersonalData.direct_manager_evaluation)[index].rating}
                                                        starCount={5}
                                                    // name='rating'
                                                    /> </th>
                                                    {/* <th scope="col">  <StarRatings
                                                            starDimension="30px"
                                                            starRatedColor="#ffff00"
                                                            starHoverColor="#ffff00"
                                                            starEmptyColor="#A9A9A9"
                                                            changeRating={(value) => this.dra_change(value, index)}
                                                            rating={singleData.rating}
                                                            numberOfStars={5}
                                                            // pointer-events = "none"
                                                            // ignoreInlineStyles={false}
                                                            style={{ pointerEvents: "none" }}
                                                            // name='rating'
                                                    /> </th> */}
                                                    <th scope="col">{JSON.parse(this.state.viewPersonalData.direct_manager_evaluation)[index].remark} </th>
                                                    <th scope="col">{JSON.parse(this.state.viewPersonalData.direct_manager_evaluation)[index].performance} </th>
                                                </>
                                            : null
                                        }
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                        <table id='overall'>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="float-right">
                                    <th className="col">{` Overall Performance Avg.   ${dra_overAllPerformance.toFixed(2)}%`} </th>
                                </tr>
                            </tbody>
                        </table>

                        <div className="hr"></div>

                        {/* ================================================ DHE ============================================= */}

                        {this.state.userData.role !== "DRA" ?
                            <>
                                <h3 id='title'><b>Depart Head Evaluation</b></h3>
                                <table id='employee' className="table table-bordered">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Score</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Performance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dhe_form.map((singleData, index) => (
                                            <tr key={index}>
                                                <th scope="col"> {index + 1} </th>
                                                <th scope="col"> {singleData.particulars} </th>
                                                {(JSON.parse(this.state.viewPersonalData.direct_manager_evaluation).length > 0) ?
                                                    (JSON.parse(this.state.viewPersonalData.direct_manager_evaluation)[0].rating <= 0 || this.state.userData.role !== 'A') ?
                                                        <>
                                                            {/* <th scope="col">  <StarRatingComponent
                                                                // starDimension="30px"
                                                                starColor="#ffff00"
                                                                // starHoverColor="#ffff00"
                                                                emptyStarColor="#808080"
                                                                // starHoverText="ASD"
                                                                onStarClick={(value) => this.dhe_change(value, index)}
                                                                value={singleData.rating}
                                                                starCount={5}
                                                            // name='rating'
                                                            /> </th> */}
                                                            <th scope="col">  <StarRatings
                                                                starDimension="30px"
                                                                starRatedColor="#ffff00"
                                                                starHoverColor="#ffff00"
                                                                starEmptyColor="#A9A9A9"
                                                                changeRating={(value) => this.dhe_change(value, index)}
                                                                rating={singleData.rating}
                                                                numberOfStars={5}
                                                                // pointer-events = "none"
                                                                // ignoreInlineStyles={false}
                                                                style={{ pointerEvents: "none" }}
                                                            // name='rating'
                                                            /> </th>
                                                            <th scope="col">{singleData.remark}</th>
                                                            <th scope="col"> {singleData.score}% </th>
                                                        </>
                                                        :
                                                        <>
                                                            <th scope="col"> <StarRatings
                                                                // starDimension="30px"
                                                                starColor="#ffff00"
                                                                // starHoverColor="#ffff00"
                                                                emptyStarColor="#808080"
                                                                // starHoverText="ASD"
                                                                // changeRating={(value) => this.dhe_change(value, index)}
                                                                value={JSON.parse(this.state.viewPersonalData.department_head_evaluation)[index].rating}
                                                                starCount={5}
                                                            // name='rating'
                                                            /> </th>
                                                            <th scope="col">{JSON.parse(this.state.viewPersonalData.department_head_evaluation)[index].remark} </th>
                                                            <th scope="col">{JSON.parse(this.state.viewPersonalData.department_head_evaluation)[index].performance} </th>
                                                        </>
                                                    : null
                                                }
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </>
                            :
                            null
                        }
                        <div className="hr"></div>
                    </>
                }
                {/* ========================================= OVERALL =================================================== */}

                <h3 id='title'><b>Overall Performance</b></h3>
                <div id='container' className='col-12'>
                    <div className="row">
                        <div className="col-sm-8">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td className="text-right">1</td>
                                        <td className="text-left">Human Resource</td>
                                    </tr>
                                    {this.state.userData.role !== 'A' &&
                                        <>
                                            <tr>
                                                <td className="text-right">2</td>
                                                <td className="text-left">Direct Manager</td>
                                            </tr>
                                            {this.state.userData.role !== "DRA" &&
                                                <tr>
                                                    <td className="text-right">3</td>
                                                    <td className="text-left">Department Head</td>
                                                </tr>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="col-sm-4">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td className="text-right">HR (Attendance)</td>
                                        <td className="text-left"> {hr_overAllPerformance.toFixed(2)} %</td>
                                    </tr>
                                    {this.state.userData.role !== 'A' &&
                                        <>
                                            <tr>
                                                <td className="text-right">DM (Individual Performance)</td>
                                                <td className="text-left">  {dra_overAllPerformance.toFixed(2)}%</td>
                                            </tr>
                                            {this.state.userData.role !== "DRA" &&
                                                <tr>
                                                    <td className="text-right">Dept. Head</td>
                                                    <td className="text-left">  {dhe_overAllPerformance.toFixed(2)}%</td>
                                                </tr>
                                            }
                                        </>
                                    }
                                    <tr>
                                        <td className="text-right"><b>Overall</b></td>
                                        <td className="text-right"><b> {kpi_performance.toFixed(2)}%</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="hr"></div>

                {/* ========================================= BUTTON ==================================================== */}
                {this.state.userData.role === "A" &&
                    //   this.state.hr_overAllPerformance <= 0 &&
                    <div id="button">
                        {!this.state.loader ?
                            <button className="btn btn-primary px-5" onClick={() => { this.insertData() }}>SAVE</button>
                            : <div style={{ marginTop: 15 }}>
                                <ClipLoader
                                    // css={override}
                                    size={40}
                                    color="#123abc"
                                    loading={true} />
                            </div>
                        }

                    </div>
                }
                {this.state.userData.role !== "A" &&
                    <div id="button">
                        {!this.state.loader ?
                            <button className="btn btn-primary px-5" onClick={() => this.updateData()}>UPDATE</button>
                            : <div style={{ marginTop: 15 }}>
                                <ClipLoader
                                    // css={override}
                                    size={40}
                                    color="#123abc"
                                    loading={true} />
                            </div>
                        }
                    </div>
                }

                {/* {this.state.userData.role === "HEADS" &&
                    <div id="button">
                        <button className="btn btn-primary px-5" onClick={() => this.updateDataHead()}>UPDATE</button>
                    </div>
                } */}

                <div className="hr"></div>

            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        insertPersonalData: (data) => dispatch(insertPersonalData(data)),
        updatePersonalData: (data) => dispatch(updatePersonalData(data)),
        updatePersonalDataByDH: (data) => dispatch(updatePersonalDataByDH(data)),
        viewPersonalData: (data) => dispatch(viewPersonalData(data)),
        fetchData: (props) => dispatch(fetchData(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetail);