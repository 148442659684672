/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { personalData, viewPersonalData, sendemail, recieveSelfEvaluationForm, insertSelfEvaluationData, onLogout } from '../../store/actions/dataAction';
import StarRatings from 'react-star-ratings';
import '../HR Evaluation/Data.css';
import ClipLoader from "react-spinners/ClipLoader";

class Insert extends Component {
    state = {
        loader: false,
        personalData: {
            id: '',
            emp_code: '',
            name: '',
            email: '',
            department: '',
            designation: '',
            DRA: '',
            head_departments: '',
            role: ''
        },
        viewPersonalData: {
            id: '',
            emp_id: '',
            emp_code: '',
            emp_name: '',
            human_resource: '[]',
            hr_performance: '',
            direct_manager_evaluation: '[]',
            dra_performance: '',
            department_head_evaluation: '[]',
            dhe_performance: '',
            overall_performance: '',
            year: '',
            quarter: '',
        },
        dra_form: [
            { particulars: "Delivery of Task", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Efficiency in task", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Communication", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "New Skills / Technical Skills", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Independent", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Team Work", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Integrity", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Co-workers and Peer relation", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Dependability", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Relation with CX", rating: 0, score: 0, remark: "", performance: 0 },
            { particulars: "Teambuilding Outdoor Activities", rating: 0, score: 0, remark: "", performance: 0 },
        ],
        dra_overAllPerformance: 0,
        show: true

    }

    default_remarks = [
        { rating: 1, remark: "Needs Action", performance: 20, score: 1 },
        { rating: 2, remark: "Below Expectations", performance: 40, score: 2 }, { rating: 3, remark: " Successfully Achieved", performance: 60, score: 3 },
        { rating: 5, remark: "Exceeds Expectations", performance: 80, score: 4 }, { rating: 4, remark: "Exceptional", performance: 100, score: 5 }
    ];
    quarter = ["Q1-(JAN-APR)", "Q2-(MAY-AUGUST)", "Q3-(SEPTEMBER-DECEMBER)"];

    dra_change = (value, index) => {
        let tempDraForm = [...this.state.dra_form];
        tempDraForm[index] = Object.assign({}, tempDraForm[index], this.calc_score(value))
        tempDraForm[index].rating = value;
        this.setState({ dra_form: tempDraForm, dra_overAllPerformance: this.calcFormPerformance(tempDraForm, "dra"), });
    }

    calc_kpi_performance = () => {
        return this.state.dra_overAllPerformance
    }

    calc_score = (score) => {
        let calcScore = null;

        switch (score) {
            case 5:
                calcScore = this.default_remarks[score - 1];
                break;
            case 4:
                calcScore = this.default_remarks[score - 1];
                break;
            case 3:
                calcScore = this.default_remarks[score - 1];
                break;
            case 2:
                calcScore = this.default_remarks[score - 1];
                break;
            case 1:
                calcScore = this.default_remarks[score - 1];
                break;

            default:
                calcScore = this.default_remarks[score - 1];
                break;
        }

        return calcScore;
    }

    calcFormPerformance = (form, type) => {
        let tempPerformance = 0;
        form.map((item) => {
            tempPerformance += item.score;
        })
        let performance = ((tempPerformance / (form.length * 5)) * 10);

        return performance
    }

    componentDidMount = () => {
        if (this.props.match.params.emp_code === this.props.userData.emp_code.toString()) {
            let data = { emp_code: this.props.match.params.emp_code }
            this.props.recieveSelfEvaluationForm(data).then((_data) => {
                this.setState({ personalData: _data[0] })

            })
        }
        else
            this.props.history.goBack()
    }

    insertData = () => {
        this.setState({loader:true})
        const data = {
            emp_id: this.state.personalData.id,
            emp_code: this.state.personalData.emp_code,
            emp_name: this.state.personalData.name,
            emp_email: this.state.personalData.email,
            evaluation: JSON.stringify(this.state.dra_form),
            total: this.state.dra_overAllPerformance,
            emp_department: this.state.personalData.department,
            emp_designation: this.state.personalData.designation,
            emp_departmentHead: this.state.personalData.head_departments,
            quarter: this.props.match.params.quarter,
            year: this.props.match.params.year

            // selectedQuarter: this.state.selectedQuarter,
            // currentYear: this.state.currentYear,
        };

        this.props.insertSelfEvaluationData(data).then((data) => {
        this.setState({loader:false})
            if (data.err) {
                alert("Alert", data.err)
            }
            else {
                alert("SUBMITED")
                this.props.history.push({ pathname: `/detail/${data.emp_code}` })
            }

        });
    }

    logout = () => {
        this.props.onLogout()
        this.props.history.push({ pathname: '/' })
    }

    render() {
        const { personalData, dra_form, dra_overAllPerformance } = this.state;

        return (

            <div>
                {/* ================================================ SIDE MENU ============================================================ */}
                {/* <div className="nav-side-menu">
                    <div className="brand">DASHBOARD</div>
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                    <div className="menu-list">

                        <ul id="menu-content" className="menu-content collapse out">

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.logout()}> Logout </a>
                            </li>

                        </ul>
                    </div>
                </div> */}

                <div className="container home">
                    <div className="hr"></div>
                    <h1 id='title'><b><u>Self Evaluation</u></b></h1>
                    <div id="p_Data" className='col-12'>
                        <div className="col-8">
                            <div>Employee Code: <b> {personalData.emp_code} </b></div>
                            <div>Name: <b>{personalData.name} </b></div>
                            <div>Department: <b> {personalData.department} </b></div>
                            <div>Designation: <b> {personalData.designation} </b></div>
                            {/* <div>DRA: <b> {personalData.DRA} </b></div> */}
                            <div>Head of Department: <b> {personalData.head_departments} </b></div>
                            <div>Quarter: <b>{this.props.match.params.quarter} </b></div>
                            <div>Year: <b>{this.props.match.params.year} </b></div>
                        </div>
                    </div>

                    <div className="hr"></div>

                    {/*============================================== PARAMETERS ============================================================  */}
                    <h3 id='title'><b>Parameters for Performance</b></h3>
                    <div id='container' className='col-12'>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            {/* <td className="text-right">1- </td> */}
                                            <td className="text-left">100% <StarRatings
                                                starDimension="20px"
                                                starSpacing="0px"
                                                starRatedColor="#ffff00"
                                                starHoverColor="#ffff00"
                                                starEmptyColor="#A9A9A9"
                                                rating={5}
                                                numberOfStars={5}
                                            // name='rating'
                                            /> as <b>Exceptional</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">2- </td> */}
                                            <td className="text-left">80% <StarRatings
                                                starDimension="20px"
                                                starSpacing="0px"
                                                starRatedColor="#ffff00"
                                                starHoverColor="#ffff00"
                                                starEmptyColor="#A9A9A9"
                                                rating={4}
                                                numberOfStars={5}
                                            // name='rating'
                                            /> as <b>Exceeds Expectations</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">3- </td> */}
                                            <td className="text-left">60% <StarRatings
                                                starDimension="20px"
                                                starSpacing="0px"
                                                starRatedColor="#ffff00"
                                                starHoverColor="#ffff00"
                                                starEmptyColor="#A9A9A9"
                                                rating={3}
                                                numberOfStars={5}
                                            // name='rating'
                                            /> as <b>Successfully Achieved</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">4- </td> */}
                                            <td className="text-left">40% <StarRatings
                                                starDimension="20px"
                                                starSpacing="0px"
                                                starRatedColor="#ffff00"
                                                starHoverColor="#ffff00"
                                                starEmptyColor="#A9A9A9"
                                                rating={2}
                                                numberOfStars={5}
                                            // name='rating'
                                            /> as <b>Below Expectations</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">5- </td> */}
                                            <td className="text-left">20% <StarRatings
                                                starDimension="20px"
                                                starSpacing="0px"
                                                starRatedColor="#ffff00"
                                                starHoverColor="#ffff00"
                                                starEmptyColor="#A9A9A9"
                                                rating={1}
                                                numberOfStars={5}
                                            // name='rating'
                                            /> as <b>Needs Action</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="hr"></div>

                    {/* ===================================== EVALUATION ============================================================== */}
                    <div className="hr"></div>
                    <h3 id='title'>Direct Manager Evaluation</h3>
                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Score</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Performance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dra_form.map((singleData, index) => (
                                <tr key={index}>
                                    <th scope="col"> {index + 1} </th>
                                    <th scope="col"> {singleData.particulars} </th>
                                    <th scope="col">  <StarRatings
                                        starDimension="30px"
                                        starRatedColor="#ffff00"
                                        starHoverColor="#ffff00"
                                        starEmptyColor="#A9A9A9"
                                        changeRating={(value) => this.dra_change(value, index)}
                                        rating={singleData.rating}
                                        numberOfStars={5}
                                        // pointer-events = "none"
                                        // ignoreInlineStyles={false}
                                        style={{ pointerEvents: "none" }}
                                    // name='rating'
                                    /> </th>
                                    <th scope="col">{singleData.remark} </th>
                                    <th scope="col"> {singleData.performance}% </th>

                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                    <table id='overall'>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="float-right">
                                <th className="col">{` Overall Performance Avg.   ${dra_overAllPerformance.toFixed(2)}%`} </th>
                            </tr>
                        </tbody>
                    </table>

                    <div className="hr"></div>

                    {/* ===================================== BUTTON ============================================================== */}
                    <div id="button">
                    {!this.state.loader ? 
                        <button className="btn btn-primary px-5" onClick={() => this.insertData()}>SUBMIT</button>
                        :<div style={{ marginTop: 15 }}>
                                <ClipLoader
                                    // css={override}
                                    size={40}
                                    color="#123abc"
                                    loading={true} />
                            </div>
                            }
                        {/* <button>SAVE</button> */}
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPersonalData: state.data.viewPersonalData,
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        sendemail: (props) => dispatch(sendemail(props)),
        recieveSelfEvaluationForm: (data, props) => dispatch(recieveSelfEvaluationForm(data, props)),
        insertSelfEvaluationData: (data) => dispatch(insertSelfEvaluationData(data)),
        onLogout: () => dispatch(onLogout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Insert);
// export default Insert;