/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './Data.css';
import { edit } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

class EditEmployee extends Component {
    state = {
        loader: false,
        addData:
            { 
                emp_code: '', 
                name: '', 
                email: '', 
                department: '', 
                designation: '', 
                emp_status: '', 
                DRA: '', 
                head_departments: '', 
                role: '' },
        selectedHeadRoleStatus: { 'head': null, 'role': null, 'status': null }


    }

    emp_status = ['Permanant', 'Probationary', 'Trainee', 'Not Applicable']
    head_departments = ['Noman Rao', 'Hasham Ahmed Qazi', 'Fahad Jeyemby']
    role = ['EMP', 'DRA', 'HR', 'HEAD']

    componentDidMount = () => {
        if (this.props.location.data)
            this.setState({ addData: this.props.location.data })
        // this.props.history.replace({ pathname: `/list` })
    }

// SIDE MENU

    navigateToList = () => {
        this.props.history.push({ pathname: `/list` })
    }

    navigateToEmail = () => {
        this.props.history.push({ pathname: `/send` })
    }

    navigateToSelfEvaluation = () => {
        this.props.history.push({ pathname: `/selfevaluation` })
    }

    navigateToAdd = () => {
        this.props.history.push({ pathname: '/add' })
    }

    navigateToEdit = () => {
        this.props.history.push({ pathname: '/edit' })
    }

    navigateToDelete = () => {
        this.props.history.push({ pathname: `/delete` })
    }

    // END

    // DROP-DOWN START
  onHeadChange = (event, variableName) => {
    let temp = { ...this.state.selectedHeadRoleStatus }
    let temp2 = { ...this.state.addData}
    temp2[variableName] = event.target.value

    temp[variableName] = event.target.value
    this.setState({ selectedHeadRoleStatus: temp, addData: temp2 })
  }
  // DROP-DOWN END

    onInputChange = (event, objectName) => {
        let temp = { ...this.state.addData };
        temp[objectName] = event.target.value;
        this.setState({ addData: temp });
    }

    updateData = () => {
        this.setState({loader: true})
        this.props.edit(this.state.addData).then((data) => {
            this.setState({loader: false})

            if (data.err)
                alert("Alert, "+ data.err)
            else
                this.props.history.push({ pathname: `/edit` })

        })
    }

    render() {
        const { addData } = this.state
        return (
            <div>

                {/* =========================================== SIDE MENU ======================================================= */}
                {/* <div className="nav-side-menu">
                    <div className="brand">Dashboard</div>
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                    <div className="menu-list">

                        <ul id="menu-content" className="menu-content collapse out">

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToList()} > List of Employees </a>
                            </li>

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToSelfEvaluation()} > Send Self Evaluation Form </a>
                            </li>

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToEmail()} > Send Email KPI </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.navigateToAdd()}> Add New Employee </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash active">
                                <a onClick={()=> this.navigateToEdit()}> Edit Employee </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.navigateToDelete()}> Delete Employee </a>
                            </li>

                        </ul>
                    </div>
                </div> */}

                {/* ================================================== ADD DATA =========================================================== */}
                <div className="container home">
                    <div className="hr"></div>
                    <h1 id="title" style={{ fontSize: 26 }}><b><u>Edit Employee</u></b></h1>
                    <div className="hr"></div>

                    {/* {addData.map((item, index) => ( */}
                    <div className="col col-12">

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Code:</b></label>
                            <input value={addData.emp_code} type="number" className="form-control" aria-describedby="emailHelp" placeholder="Employee Code" size="20" onChange={(event) => this.onInputChange(event, "emp_code")} />
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Name:</b></label>
                            <input value={addData.name} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Name" size="20" onChange={(event) => this.onInputChange(event, "name")} />
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Email:</b></label>
                            <input value={addData.email} type="email" className="form-control" aria-describedby="emailHelp" placeholder="Employee Email" size="20" onChange={(event) => this.onInputChange(event, "email")} />
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Department:</b></label>
                            <input value={addData.department} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Department" size="20" onChange={(event) => this.onInputChange(event, "department")} />
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Designation:</b></label>
                            <input value={addData.designation} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Designation" size="20" onChange={(event) => this.onInputChange(event, "designation")} />
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee DRA:</b></label>
                            <input value={addData.DRA} type="number" className="form-control" aria-describedby="emailHelp" placeholder="Employee DRA (DRA's EMPLOYEE CODE)" size="20" onChange={(event) => this.onInputChange(event, "DRA")} />
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Head of Department:</b></label>
                            {/* <input value={addData.head_departments} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Head of Department (example: FB, HB, AB)" size="20" onChange={(event) => this.onInputChange(event, "head_departments")} /> */}
                            <select onChange={(event) => this.onHeadChange(event, 'head_departments')} className="browser-default custom-select">Select Quarter:
                                <option defaultValue="selected">{addData.head_departments}</option>
                                {this.head_departments.map((item, index) => (
                                    item !== addData.head_departments &&
                                    <option key={index} value={item}> {item} </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Role:</b></label>
                            {/* <input value={addData.role} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Role" size="20" onChange={(event) => this.onInputChange(event, "role")} /> */}
                            <select onChange={(event) => this.onHeadChange(event, 'role')} className="browser-default custom-select">Select Quarter:
                                <option defaultValue="selected">{addData.role}</option>
                                {this.role.map((item, index) => (
                                    item !== addData.role &&
                                    <option key={index} value={item}> {item} </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label style={{ fontSize: 18 }}><b>Employee Status:</b></label>
                            {/* <input value={addData.emp_status} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Status" size="20" onChange={(event) => this.onInputChange(event, "emp_status")} /> */}
                            <select onChange={(event) => this.onHeadChange(event, 'emp_status')} className="browser-default custom-select">Select Quarter:
                                <option defaultValue="selected">{addData.emp_status}</option>
                                {this.emp_status.map((item, index) => (
                                    item !== addData.emp_status &&
                                    <option key={index} value={item}> {item} </option>
                                ))}
                            </select>
                        </div>

                    </div>
                    {/* ))} */}

                    {/* ========================================= BUTTON ==================================================== */}
                    <div className="hr"></div>
                    <div id="button">
                    {!this.state.loader ? 
                        <button className="btn btn-primary px-5" onClick={() => this.updateData()}>UPDATE</button>
                        :<div style={{ marginTop: 15 }}>
                            <ClipLoader
                                // css={override}
                                size={40}
                                color="#123abc"
                                loading={true} />
                        </div>
                    }
                        {/* <button>SAVE</button> */}
                    </div>

                    <div className="hr"></div>

                </div>

            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        edit: (data) => dispatch(edit(data)),
    }
}

export default connect(null, mapDispatchToProps)(EditEmployee);

// export default EditEmployee;