/* eslint-disable no-unused-expressions */
import { uiStartLoading, uiStopLoading } from './uiAction'
// export const url = "http://localhost:3002";
export const url = "https://nolin-kpi.vanwala.pk"
const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
}
const apiFetch = (apiUrl, apiMethod, apiHeader, apiBody, auth = true) => {
  return (dispatch, getState) => {
    //add Authorization token to header
    auth ? header.Authorization = `Bearer ${getState().data.userToken}` : null;
    //add device_id to body
    auth ? apiBody.emp_id = getState().data.userData.id : null;

    let options = {
      method: apiMethod,
      headers: header,
    }

    if (apiMethod === "POST") {
      options.body = JSON.stringify(apiBody);
    }
    dispatch(uiStartLoading())
    return new Promise(async (resolve, reject) => {
      fetch(`${url}/${apiUrl}`, options)
        .then(res => {
          if (!res.ok) { return reject('Error') }
          return res.json()
        })
        .then(resJSON => {
          dispatch(uiStopLoading())
          resolve(resJSON)
        })
    });
  };
};

// ========================================================= APIs ==============================================================

export const personalData = (emp_code) => {
  return apiFetch('api/employee/personalData', 'POST', null, { emp_code })
};

export const viewPersonalData = (data) => {
  return apiFetch('api/employee/viewPersonalData', 'POST', null, { data })
};

export const insertPersonalData = (data) => {
  return apiFetch('api/employee/insertPersonalData', 'POST', null, { data })
};

export const updatePersonalData = (data) => {
  return apiFetch('api/employee/updatePersonalData', 'POST', null, { data })
};

export const updatePersonalDataByDH = (data) => {
  return apiFetch('api/employee/updatePersonalDataByDH', 'POST', null, { data })
};

export const fetchData = (data) => {
  return apiFetch('api/employee/fetchData', 'POST', null, { data })
};

export const add = (data) => {
  return apiFetch('api/employee/add', 'POST', null, { data })
};

export const edit = (data) => {
  return apiFetch('api/employee/edit', 'POST', null, { data })
};

export const removed = (data) => {
  return apiFetch('api/employee/removed', 'POST', null, { data })
};

export const sendemail = (data) => {
  return apiFetch('api/employee/sendemail', 'POST', null, { data })
};

export const sendEmailEmployee = (data) => {
  return apiFetch('api/employee/sendEmailEmployee', 'POST', null, { data })
};

export const login = (data) => {
  return apiFetch('api/employee/login', 'POST', null, { data }, false)
};

export const changePwd = (data) => {
  return apiFetch('api/employee/changePwd', 'POST', null, { data }, false)
};

export const sendCodeEmail = (data) => {
  return apiFetch('api/employee/sendCodeEmail', 'POST', null, { data }, false)
};

export const insertSelfEvaluationData = (data) => {
  return apiFetch('api/employee/insertSelfEvaluationData', 'POST', null, { data })
};

export const sendSelfEvaluationForm = (data) => {
  return apiFetch('api/employee/sendSelfEvaluationForm', 'POST', null, { data })
};

export const recieveSelfEvaluationForm = (data) => {
  return apiFetch('api/employee/recieveSelfEvaluationForm', 'POST', null, { data })
};

export const viewSelfEvaluationForm = (data) => {
  return apiFetch('api/employee/viewSelfEvaluationForm', 'POST', null, { data })
};

export const employeeLogin = (emp_id) => {
  return apiFetch('api/employee/employeeLogin', 'POST', null, { emp_id })
};


export const onEmployeeViewPersonalData = (data) => {
  return apiFetch('api/employee/onEmployeeViewPersonalData', 'POST', null, { data })
};

export const insertAnnualSelfAssessment = (data) => {
  return apiFetch('api/employee/insertAnnualSelfAssessment', 'POST', null, { data })
};


export const checkAnnualSelfCreated = (data) => {
  return apiFetch('api/employee/checkAnnualSelfCreated', 'POST', null, { data })
};


export const getAnnualSelfAssessment = (data) => {
  return apiFetch('api/employee/getAnnualSelfAssessment', 'POST', null, { data })
};


// ============================================================= REDUX =========================================================

export const setPersonalData = (data) => {
  return {
    type: 'SET_PERSONAL_DATA',
    personalData: data
  }
};

export const onSetViewPersonalData = (data) => {
  return {
    type: 'SET_VIEW_PERSONAL_DATA',
    data
  }
};

export const setUserData = (data) => {
  return {
    type: 'SET_USER_DATA',
    userData: data
  }
};

export const setUserToken = (data) => {
  return {
    type: 'SET_USER_TOKEN',
    userToken: data
  }
};

export const onLogout = () => {
  return {
    type: 'USER_LOGOUT',
  }
};
