/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { personalData } from '../../store/actions/dataAction';
import './Data.css';

class EmployeeView extends Component {
    state = {
        viewPersonalData: this.props.viewPersonalData
    }
    componentDidMount = () => {
        if (this.state.viewPersonalData === null)
            this.props.history.replace({ pathname: `/list` })
    };

    kpiDetail = (viewPersonalData) => {
        this.props.history.push({ pathname: `/KPIDetail/${viewPersonalData.emp_code}`, viewPersonalData })
    }

    // SIDE MENU

    navigateToList = () => {
        this.props.history.push({ pathname: `/list` })
    }

    // END

    render() {
        const { viewPersonalData } = this.state
        if (viewPersonalData !== null) {
            return (
                <div>
                    {/* ================================================ SIDE MENU ============================================================ */}
                    {/* <div className="nav-side-menu">
                        <div className="brand">DASHBOARD</div>
                        <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                        <div className="menu-list">

                            <ul id="menu-content" className="menu-content collapse out">
                                <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                    <a onClick={() => this.navigateToList()}> List of Employees </a>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                    {/* ================================================ SIDE MENU ============================================================ */}

                    <div className="container home">
                        <h3 id='title'><b><u>SUMMARY</u></b></h3>

                        <div id="p_Data" className='col-12'>
                            <div className="col-8">
                                <div>Employee Code: <b> {viewPersonalData[0].emp_code} </b></div>
                                <div>Name: <b>{viewPersonalData[0].emp_name} </b></div>
                                <div>Employment Status: <b>{viewPersonalData[0].emp_status} </b></div>
                                <div>Department: <b>{viewPersonalData[0].department} </b></div>
                            </div>
                        </div>

                        <table id='employee' className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">Year</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">HR Performance</th>
                                    <th scope="col">DRA Performance</th>
                                    <th scope="col">DH Performance</th>
                                    <th scope="col">Overall Performance</th>
                                    <th scope="col">KPI Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(viewPersonalData.map((data, index) => (
                                    <tr key={index}>
                                        {/* <th>{index + 1}</th> */}
                                        <th>{data.year}</th>
                                        <th>{data.quarter}</th>
                                        <td>{JSON.parse(data.hr_performance).toFixed(2)}%</td>
                                        <td>{JSON.parse(data.dra_performance).toFixed(2)}% </td>
                                        <td>{data.dhe_performance}%</td>
                                        <td>{JSON.parse(data.overall_performance).toFixed(2)}%</td>
                                        <td><button className="btn btn-primary" onClick={() => this.kpiDetail(data)}>View Detail</button></td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>

                    </div>
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
}
const mapStateToProps = state => {
    return {
        viewPersonalData: state.data.viewPersonalData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        // viewPersonalData: (props) => dispatch(viewPersonalData(props))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeView);