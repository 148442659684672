import React, { Component } from 'react';
import './SideBar.css';
import { connect } from 'react-redux';
import { onLogout, } from '../store/actions/dataAction';

class SideBar extends Component {

    logout = () => {
        this.props.onLogout()
        this.props.history.push({ pathname: '/' })
    }

    // SIDE MENU START
    navigateToList = () => {
        this.props.history.push({ pathname: `/list` })
    }


    navigateToAdd = () => {
        this.props.history.push({ pathname: '/add' })
    }

    navigateToEmail = () => {
        this.props.history.push({ pathname: `/send` })
    }

    navigateToSelfEvaluation = () => {
        this.props.history.push({ pathname: `/selfevaluation` })
    }

    navigateToEdit = () => {
        this.props.history.push({ pathname: '/edit' })
    }

    navigateToDelete = () => {
        this.props.history.push({ pathname: `/delete` })
    }

    navigateToDetail = () => {
        this.props.history.push({ pathname: `/detail/${this.props.userData.emp_code}` })
    }

    navigateToAnnualSelfAssessment = () =>{
        this.props.history.push({ pathname: `/annualSelfAssessment/2023` })

    }
    // END

    render() {
        const { children } = this.props;
        const pathname = this.props.history.location.pathname.split('/')[1];
        return (
            <>
                <div className="nav-side-menu">
                    <div className="brand">DASHBOARD</div>
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                    <div className="menu-list">

                        <ul id="menu-content" className="menu-content collapse out">

                            {['EMP', 'DRA', 'HR', 'HEAD'].includes(this.props.userData.role) && (this.props.userData.emp_code !== 1) &&
                                <>
                                    <li onClick={() => { this.navigateToDetail(); }} data-toggle="collapse" data-target="#products" className={`collapsed dash ${pathname === 'detail' ? 'active' : ''}`}>
                                        <a> KPI Details </a>
                                    </li> 
                                    <li onClick={() => { this.navigateToAnnualSelfAssessment(); }} data-toggle="collapse" data-target="#products" className={`collapsed dash ${pathname === 'annualSelfAssessment' ? 'active' : ''}`}>
                                        <a> Annual Self Assessment </a>
                                    </li> 
                                </>
                            }

                            {this.props.userData.role !== "EMP" &&
                                <li onClick={() => { this.navigateToList(); }} data-toggle="collapse" data-target="#products" className={`collapsed dash ${pathname === 'list' ? 'active' : ''}`}>
                                    <a > {this.props.userData.role !== 'A' ? "Team Members" : "List of Employees"} </a>
                                </li>
                            }

                            {this.props.userData.role === "A" && <>

                                <li onClick={() => { this.navigateToSelfEvaluation(); }} data-toggle="collapse" data-target="#products" className={`collapsed dash ${pathname === 'selfevaluation' ? 'active' : ''}`}>
                                    <a  > Send Self Evaluation Form </a>
                                </li>

                                <li onClick={() => { this.navigateToEmail(); }} data-toggle="collapse" data-target="#products" className={`collapsed dash ${pathname === 'send' ? 'active' : ''}`}>
                                    <a  > Send Email KPI </a>
                                </li>

                                <li onClick={() => { this.navigateToAdd(); }} data-toggle="collapse" data-target="#service" className={`collapsed dash ${pathname === 'add' ? 'active' : ''}`}>
                                    <a > Add New Employee </a>
                                </li>

                                <li onClick={() => { this.navigateToEdit(); }} data-toggle="collapse" data-target="#service" className={`collapsed dash ${pathname === 'edit' ? 'active' : ''}`}>
                                    <a > Edit Employee </a>
                                </li>

                                <li onClick={() => { this.navigateToDelete(); }} data-toggle="collapse" data-target="#service" className={`collapsed dash ${pathname === 'delete' ? 'active' : ''}`}>
                                    <a > Delete Employee </a>
                                </li>
                            </>
                            }

                            <li onClick={() => this.logout()} data-toggle="collapse" data-target="#service" className={`collapsed dash`}>
                                <a > Logout </a>
                            </li>

                        </ul>
                    </div>
                </div>

                {children}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(onLogout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);