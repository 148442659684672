/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../login/login.css';
import { login, personalData, changePwd, setUserData, setUserToken } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
import Popup from "reactjs-popup";
import jwtDecode from 'jwt-decode';
import 'bootstrap/dist/css/bootstrap.css';

class EmployeeLogin extends Component {
    state = {
        controls: {
            email: {
                value: '',
                valid: false
            },
            password: {
                value: '',
                valid: false
            }
        },
        show: false,
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };


    onInputValue = (event, type) => {
        let temp = { ...this.state.controls }
        temp[type].value = event.target.value
        this.setState({ controls: temp })
    }

    login = () => {
        const data = {
            email: this.state.controls.email.value,
            pwd: this.state.controls.password.value
        }
        this.props.login(data).then((data) => {
            let setUserData = data;
            if (setUserData.token) {
                const decodedData = jwtDecode(setUserData.token)
                this.props.setUserData(decodedData)
                this.props.setUserToken(setUserData.token)
                this.props.history.push({ pathname: `/list` })
            }
            else {
                alert("Email or Password is incorrect")
            }
        });
    }

    changePwd = () => {
        const data = {
            email: this.state.controls.email.value,
            pwd: this.state.controls.password.value
        }

        this.props.changePwd(data).then((data) => {
            if (data.err)
                alert("Alert", data.err)
            else
                this.props.history.push({ pathname: `/` })
        })
    }

    render() {
        return (
            <div className="container">
                <div className="d-flex justify-content-center h-100">
                    <div className="card">
                        <div className="card-header">
                            <h3>Sign In</h3>
                        </div>

                        <div className="card-body">
                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-user"></i></span>
                                </div>
                                <input
                                    value={this.state.controls.email.value}
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className="form-control"
                                    aria-describedby="emailHelp"
                                    size="20"
                                    onChange={(event) => this.onInputValue(event, "email")}
                                />

                            </div>

                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-key"></i></span>
                                </div>
                                <input
                                    value={this.state.controls.password.value}
                                    type="password"
                                    name="pass"
                                    placeholder="Password"
                                    className="form-control"
                                    aria-describedby="emailHelp"
                                    size="20"
                                    onChange={(event) => this.onInputValue(event, "password")}
                                />
                            </div>
                                <button className="btn btn-primary" size="50" onClick={() => this.login()}>
                                Sign in
						        </button>
                        </div>

                        <div className="card-footer">
                            <div className="d-flex justify-content-center links">
                                {`Want to change a`}
                                <Popup modal trigger={<a href="#" className="login100-form-btn btn-primary txt2 hov1 header" onClick={() => this.showModal()} >
                                    {`Password?`}
                                </a>}>
                                    <a href="" className="close" onClick={() => this.hideModal()}>
                                        &times;
                                    </a>
                                    <div className="form-group">
                                        <label style={{ fontSize: 18 }}><b>Change Password:</b></label>
                                        <input
                                            value={this.state.controls.email.value}
                                            type="email"
                                            name="email"
                                            placeholder="email"
                                            className="form-control input-small"
                                            onChange={(event) => this.onInputValue(event, "email")}
                                        />
                                        <div className="hr-modal"></div>
                                        <input
                                            value={this.state.controls.password.value}
                                            type="password"
                                            name="pass"
                                            placeholder="Password"
                                            className="form-control input-small"
                                            onChange={(event) => this.onInputValue(event, "password")}
                                        />
                                    </div>

                                    <div className="container-login100-form-btn m-t-20">
                                        <button className="btn-primary btn-modal" style={{ textAlign: 'end' }} onClick={() => this.changePwd()}>
                                            Save
						         </button>
                                    </div>
                                </Popup>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(login(data)),
        changePwd: (data) => dispatch(changePwd(data)),
        personalData: (props) => dispatch(personalData(props)),
        setUserData: (data) => dispatch(setUserData(data)),
        setUserToken: (data) => dispatch(setUserToken(data)),
    }
}

export default connect(null, mapDispatchToProps)(EmployeeLogin);

// export default Login;