/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { personalData, onLogout, viewSelfEvaluationForm } from '../../store/actions/dataAction';
import '../HR Evaluation/Data.css';

class Detail extends Component {
    state = {
        viewPersonalData: this.props.viewPersonalData
    }
    componentDidMount = () => {
        if (this.state.viewPersonalData === null || this.state.viewPersonalData.length === 0) {
            alert("NO DATA")
            if(this.props.userData.department === 'Management') {
                this.props.history.push({ pathname: '/link' })
            } else {
                this.props.onLogout()

            }
        }
    };

    kpiDetail = (viewPersonalData) => {
        this.props.history.push({ pathname: `/KPIDetail/${viewPersonalData.emp_code}`, viewPersonalData })
    }

    navigateToSelfEvaluationForm = (data) => {
        this.props.viewSelfEvaluationForm({ ...data, selfEval: true }).then(_data => {
            let temp = _data[0]
            this.props.history.push({ pathname: `/viewselfevaluationform/${data.emp_code}`, temp })

        })
    }

    // SIDE MENU
    logout = () => {
        this.props.onLogout()
        this.props.history.push({ pathname: '/' })
    }

    // END

    render() {
        const { viewPersonalData } = this.state
        if (viewPersonalData && viewPersonalData.length > 0) {
            return (
                <div>
                    {/* ================================================ SIDE MENU ============================================================ */}
                    {/* <div className="nav-side-menu">
                        <div className="brand">DASHBOARD</div>
                        <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                        <div className="menu-list">

                            <ul id="menu-content" className="menu-content collapse out">

                                <li data-toggle="collapse" data-target="#service" className="collapsed dash active">
                                    <a > KPI Details </a>
                                </li>

                                <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                    <a onClick={() => this.logout()}> Logout </a>
                                </li>

                            </ul>
                        </div>
                    </div> */}

                    <div className="container home">
                        <h3 id='title'><b><u>SUMMARY</u></b></h3>

                        <div id="p_Data" className='col-12'>
                            <div className="col-8">
                                <div>Employee Code: <b> {viewPersonalData[0].emp_code} </b></div>
                                <div>Name: <b>{viewPersonalData[0].emp_name} </b></div>
                                <div>Employement Status: <b>{viewPersonalData[0].emp_status} </b></div>
                                <div>Department: <b>{viewPersonalData[0].department} </b></div>
                            </div>
                        </div>

                        <table id='employee' className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">Year</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">HR Performance</th>
                                    <th scope="col">DRA Performance</th>
                                    <th scope="col">DH Performance</th>
                                    <th scope="col">Overall Performance</th>
                                    <th scope="col">KPI Detail</th>
                                    <th scope="col">Self Evaluation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(viewPersonalData.map((data, index) => (
                                    (data.hr_performance !== 0 && data.dra_performance !== 0 && data.dhe_performance !== 0) &&
                                    <tr key={index}>
                                        {/* <th>{index + 1}</th> */}
                                        <th>{data.year}</th>
                                        <th>{data.quarter}</th>
                                        <td>{JSON.parse(data.hr_performance).toFixed(2)}%</td>
                                        <td>{JSON.parse(data.dra_performance).toFixed(2)}% </td>
                                        <td>{data.dhe_performance}%</td>
                                        <td>{JSON.parse(data.overall_performance).toFixed(2)}%</td>
                                        <td><button className="btn btn-primary" onClick={() => this.kpiDetail(data)}>View Detail</button></td>
                                        <td><button className="col btn btn-primary btn-sm" onClick={() => this.navigateToSelfEvaluationForm(data)}>View</button></td>
                                    </tr>
                                )))}

                            </tbody>
                        </table>

                    </div>
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
}
const mapStateToProps = state => {
    return {
        viewPersonalData: state.data.viewPersonalData,
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        onLogout: () => dispatch(onLogout()),
        viewSelfEvaluationForm: (data) => dispatch(viewSelfEvaluationForm(data)),
        // viewPersonalData: (props) => dispatch(viewPersonalData(props))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);