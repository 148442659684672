/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './Data.css'
import { personalData, viewPersonalData, onSetViewPersonalData, sendemail, login, viewSelfEvaluationForm } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
// import Modal from '../../components/Modal';
// import Popup from "reactjs-popup";

import '../../components/SideBar.css';

class ListEditEmployee extends Component {
    state = {
        personalData:  this.props.getPersonalData,
        filterPersonalData:  this.props.getPersonalData,
        viewPersonalData: [],
        checkedAll: false,
        currentDate: new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear(),
        // userData: JSON.parse(localStorage.getItem('userData')),
    }

    // componentWillMount = () => {
    //     localStorage.getItem('PD') && this.setState({ personalData: JSON.parse(localStorage.getItem('PD')), filterPersonalData: JSON.parse(localStorage.getItem('PD')) })
    // }

    componentDidMount = () => {
        this.props.personalData(this.props.userData.emp_code).then(data => {
            let temp = data
            // localStorage.setItem('PD', JSON.stringify(temp))
            this.setState({ personalData: temp, filterPersonalData: temp })
        });
    }

    onInputChange = (event) => {
        this.setState({ filterPersonalData: this.state.personalData.filter((names) => { return (names.name.toLowerCase()).includes(event.target.value.toLowerCase()) }) })
    }

    onInputValue = (event, type) => {
        let temp = { ...this.state.password }
        temp[type] = event.target.value
        this.setState({ controls: temp })
    }

    navigateToInsert = (data) => {
        this.props.history.push({ pathname: `/Insert/${data.emp_code}`, data })
    }

    navigateToView = (data) => {
        this.props.viewPersonalData(data).then(_data => {
            this.props.onSetViewPersonalData(_data)
            this.props.history.push({ pathname: `/EmployeeView/${data.emp_code}` })

        });
    }

    // navigateToSelfEvaluationForm = (data) => {
    //     this.props.viewSelfEvaluationForm(data).then(_data => {
    //         let temp = _data[0]
    //         this.props.history.push({ pathname: `/viewselfevaluationform/${data.emp_code}`, temp })

    //     })
    // }



    // SIDE MENU START

    navigateToList = () => {
        this.props.history.push({ pathname: `/list` })

    }

    navigateToEmail = () => {
        this.props.history.push({ pathname: `/send` })
    }

    navigateToSelfEvaluation = () => {
        this.props.history.push({ pathname: `/selfevaluation` })
    }

    navigateToAdd = () => {
        this.props.history.push({ pathname: '/add' })
    }

    navigateToEdit = () => {
        this.props.history.push({ pathname: '/edit' })
    }

    navigateToDelete = () => {
        this.props.history.push({ pathname: `/delete` })
    }
    // END

    navigateToEditEmployee = (data) => {
        this.props.history.push({ pathname: `/editEmployee/${data.emp_code}`, data })
    }

    render() {
        return (
            <div>

                {/* ================================================ SIDE MENU ============================================================ */}
                {/* <div className="nav-side-menu">
                    <div className="brand">DASHBOARD</div>
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                    <div className="menu-list">

                        <ul id="menu-content" className="menu-content collapse out">

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToList()}> List of Employees </a>
                            </li>

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToSelfEvaluation()} > Send Self Evaluation Form </a>
                            </li>

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToEmail()} > Send Email KPI </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.navigateToAdd()}> Add New Employee </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash active">
                                <a onClick={() => this.navigateToEdit()}> Edit Employee </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.navigateToDelete()}> Delete Employee </a>
                            </li>

                        </ul>
                    </div>
                </div> */}

                {/* =========================================== LIST OF EMPLOYEES ======================================================= */}
                <div className="home px-3">

                    <div className="hr"></div>
                    <div id='title'><h1 id='title'><b><u>List Of Employees</u></b></h1>
                        {/* <p> Welcome {this.state.personalData[0]} Today is {this.state.currentDate} </p> */}
                    </div>
                    <div className="col col-12" style={{ textAlign: 'center' }}>
                        <input type="text" className="hr" style={{ borderRadius: 5, borderWidth: 3 }} size="120" placeholder={'Search'} onChange={(event) => this.onInputChange(event)} />
                    </div>

                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Emp Code</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Department</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Status</th>
                                <th scope="col">Edit Employee</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filterPersonalData.map((data, index) => (
                                <tr key={index}>
                                    <th>{data.emp_code}</th>
                                    <td>{data.name}</td>
                                    <td>{data.email}</td>
                                    <td> {data.department} </td>
                                    <td>{data.designation}</td>
                                    <td>{data.emp_status}</td>
                                    {/* <td>{`Emp Status`}</td> */}
                                    <td style={{ borderWidth: 0 }}>
                                        <button className="col btn btn-primary btn-sm" onClick={() => this.navigateToEditEmployee(data)}>EDIT</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.data.userData,
        getPersonalData: state.data.personalData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        onSetViewPersonalData: (props) => dispatch(onSetViewPersonalData(props)),
        sendemail: (props) => dispatch(sendemail(props)),
        login: (data) => dispatch(login(data)),
        viewSelfEvaluationForm: (data) => dispatch(viewSelfEvaluationForm(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListEditEmployee);

// export default PersonalData;