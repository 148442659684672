/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './Data.css'
import { personalData, viewPersonalData, onSetViewPersonalData, sendemail, login, viewSelfEvaluationForm, setPersonalData, setUserData, onLogout, onEmployeeViewPersonalData } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
import '../../components/SideBar.css';

class PersonalData extends Component {
    state = {
        personalData: this.props.getPersonalData,
        filterPersonalData: this.props.getPersonalData,
        viewPersonalData: [],
        checkedAll: false,
        currentDate: new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear(),
        userData: this.props.userData,
        currentKPI: '',
        AssessmentYear: ''
    }

    getCurrentYear = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1

        // Determine the quarter based on the current month
        const currentQuarter = Math.ceil(currentMonth / 3);
        // If it's the first quarter, subtract 1 from the current year
        const year = currentQuarter === 1 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

        return year;
    }

    componentDidMount = () => {

        if (this.state.userData === undefined)
            this.props.history.push('/')
        this.props.personalData(this.props.userData.emp_code).then(data => {
            let temp = data.filter(d=>d.emp_status === 'Permanant')
            // if (this.state.userData.role !== "A") {
            //     if (this.state.userData.emp_code === 1023) {
            //         temp = data.filter(single => { return single.head_departments === "FB" })
            //     } else {
            //         temp = data.filter(single => { return single.DRA === JSON.stringify(this.state.userData.emp_code) })
            //     }
            // }
            let kpiPerform = temp[temp.length - 1].kpi_employee_performance
            this.props.setPersonalData(temp)
            this.setState({ personalData: temp, filterPersonalData: temp, currentKPI: kpiPerform && kpiPerform.split('-KPI-')[0], AssessmentYear: this.getCurrentYear() })

        });
    }

    onInputChange = (event) => {
        this.setState({ filterPersonalData: this.state.personalData.filter((names) => { return (names.name.toLowerCase()).includes(event.target.value.toLowerCase()) }) })
    }

    onInputValue = (event, type) => {
        let temp = { ...this.state.password }
        temp[type] = event.target.value
        this.setState({ controls: temp })
    }

    navigateToInsert = (data) => {
        this.props.history.push({ pathname: `/Insert/${data.emp_code}`, data })
    }

    navigateToView = (data) => {
        this.props.viewPersonalData(data).then(_data => {
            this.props.onSetViewPersonalData(_data)
            this.props.history.push({ pathname: `/EmployeeView/${data.emp_code}` })

        });
    }

    navigateToSelfEvaluationForm = (data) => {
        this.props.viewSelfEvaluationForm(data).then(_data => {
            if (_data.length > 0) {
                let temp = _data[0]
                this.props.history.push({ pathname: `/viewselfevaluationform/${data.emp_code}`, temp })
            } else {
                alert("NO DATA")
            }

        })
    }

    navigateToAnnualSelfAssessmentView = (data) => {
        this.props.history.push({ pathname: `/annualSelfAssessmentView/${data.emp_code}` })
    }

    // SIDE MENU START
    navigateToEmail = () => {
        this.props.history.push({ pathname: `/send` })
    }

    navigateToSelfEvaluation = () => {
        this.props.history.push({ pathname: `/selfevaluation` })
    }

    navigateToAdd = () => {
        this.props.history.push({ pathname: '/add' })
    }

    navigateToEdit = () => {
        this.props.history.push({ pathname: '/edit' })
    }

    navigateToDelete = () => {
        this.props.history.push({ pathname: `/delete` })
    }

    logout = () => {
        this.props.onLogout()
        this.props.history.push({ pathname: '/' })
    }

    navigateToDetail = () => {
        this.props.onEmployeeViewPersonalData(this.props.userData.emp_code).then(data => {
            this.props.onSetViewPersonalData(data)
            this.props.history.push({ pathname: `/detail/${data[0].emp_code}` })
            // this.setState({loading: true})
        });
    }
    // END

    isAdminHead = ['A', 'HEAD'].includes(this.state.userData.role);
    render() {
        return (
            <div>

                {/* =========================================== LIST OF EMPLOYEES ======================================================= */}
                <div className="home px-3">
                    <h1 class="display-6 mt-3">{this.props.userData.role !== 'A' ? "Team Members" : "List of Employees"}</h1>
                    <div className="col col-12" style={{ textAlign: 'center', borderRadius: 5, borderWidth: 3 }}>
                        <input type="text" className="hr pl-3" style={{ width: '100%', height: 50, }} size="120" placeholder={'Search'} onChange={(event) => this.onInputChange(event)} />
                    </div>

                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Emp Code</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Employment Status</th>
                                <th scope="col">Department</th>
                                {/* <th scope="col">Designation</th> */}
                                <th scope="col">Current KPI Status <br /> <small>{this.state.currentKPI}</small></th>
                                <th scope="col">KPI Details</th>
                                <th scope="col">Self Evaluation</th>
                                {this.isAdminHead && <th scope="col">Annual Self Assessment - {this.state.AssessmentYear}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filterPersonalData.map((data, index) => {
                                let perform = data.kpi_employee_performance ? data.kpi_employee_performance.split('-KPI-') : '';
                                let hr = perform[1] > 0, dra = perform[2] > 0, dhe = perform[3] > 0
                                return (
                                    <tr key={index}>
                                        <th>{data.emp_code}</th>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.emp_status}</td>
                                        <td> {data.department} </td>
                                        {/* <td>{data.designation}</td> */}
                                        <td>
                                            <tr>
                                                <td style={{ fontSize: 'small', border: 'none' }}> HR <input type="checkbox" checked={hr} /></td>
                                                <td style={{ fontSize: 'small', border: 'none' }}> DRA <input type="checkbox" checked={dra} /></td>
                                                <td style={{ fontSize: 'small', border: 'none' }}> HEAD <input type="checkbox" checked={dhe} /></td>
                                            </tr>
                                        </td>
                                        {/* <td>{data.emp_status}</td> */}
                                        {/* <table> */}
                                        {/* <tbody> */}
                                        <td>
                                            {data.kpi_employee_id !== null ? <td style={{ borderWidth: 0 }} >
                                                <button className="col btn btn-primary btn-sm" onClick={() => this.navigateToInsert(data)}>Insert</button>
                                            </td> :
                                                <td style={{ borderWidth: 0 }} >
                                                    <button className="btn btn-primary mx-4" onClick={() => this.navigateToInsert(data)}>Insert</button>
                                                </td>
                                            }
                                            {data.kpi_employee_id !== null && <td style={{ borderWidth: 0 }} >
                                                <button className="col btn btn-primary btn-sm" onClick={() => this.navigateToView(data)}>View</button>
                                            </td>}
                                        </td>
                                        {/* </tbody> */}
                                        {/* </table> */}
                                        <td style={{ borderWidth: 0 }}>
                                            <button className="col btn btn-primary btn-sm" style={{ textAlign: 'center' }} onClick={() => this.navigateToSelfEvaluationForm(data)}>View</button>
                                        </td>
                                        {this.isAdminHead && <td style={{ borderWidth: 0 }}>
                                            <button className={`col btn ${data.annual_assessment_done ? 'btn-primary' : 'btn-secondary'} btn-sm`} style={{ textAlign: 'center' }} onClick={() => data.annual_assessment_done ? this.navigateToAnnualSelfAssessmentView(data) : null}>{data.annual_assessment_done ? 'View' : 'Pending'}</button>
                                        </td>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.data.userData,
        getPersonalData: state.data.personalData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        onSetViewPersonalData: (props) => dispatch(onSetViewPersonalData(props)),
        sendemail: (props) => dispatch(sendemail(props)),
        login: (data) => dispatch(login(data)),
        viewSelfEvaluationForm: (data) => dispatch(viewSelfEvaluationForm(data)),
        setPersonalData: (data) => dispatch(setPersonalData(data)),
        setUserData: (data) => dispatch(setUserData(data)),
        onLogout: () => dispatch(onLogout()),
        onEmployeeViewPersonalData: (props) => dispatch(onEmployeeViewPersonalData(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);

// export default PersonalData;