/* eslint-disable no-unused-vars */
import "./App.css";
import React, { Component } from "react";
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import ProtectedRoute from './Protected Route/ProtectedRoute'
import { connect } from 'react-redux';

// SCREENS
import Login from './screens/login/login';
import PersonalData from './screens/HR Evaluation/PersonalData';
import InsertEmployeeDetail from './screens/HR Evaluation/InsertEmployeeDetail';
import EmployeeView from './screens/HR Evaluation/EmployeeView';
import ViewEmployeeDatail from './screens/HR Evaluation/ViewEmployeeDetail';
import ViewEmailSend from './screens/HR Evaluation/ViewEmailSend';
import EmailSendToEmployeeView from './screens/HR Evaluation/EmailSendToEmployeeView';
import AddNewEmployee from './screens/HR Evaluation/AddNewEmployee';
import ViewSelfEvaluationMAilSend from './screens/HR Evaluation/ViewSelfEvaluationMailSend';
import SelfEvaluation from './screens/Self Evaluation/Insert';
import View from './screens/Self Evaluation/View';
import Delete from './screens/HR Evaluation/DeleteEmployee';
import ListEditEmployee from './screens/HR Evaluation/ListEditEmployee';
import EditEmployee from './screens/HR Evaluation/EditEmployee';
import Error404 from "./screens/Error Page/Error404";
import EmployeeLogin from "./screens/Self Evaluation/EmployeeLogin";
import Detail from "./screens/Self Evaluation/Detail";
import AnnualSelfAssessment from "./screens/AnnualSelfAssessment";
import AnnualSelfAssessmentVIew from "./screens/AnnualSelfAssessment/AnnualSelfAssessmentVIew";

class App extends Component {
  render() {
    const role = this.props.userData ? this.props.userData.role : 'NULL_KPI';
    const empRouteAllow = ['EMP', 'DRA', 'HR', 'HEAD'].includes(role) && this.props.userData.emp_code !== 1
    const draRouteAllow = ['A', 'DRA', 'HEAD', 'HR'].includes(role)
    const adminHeadRouteAllow = ['A', 'HEAD'].includes(role)
    const adminRouteAllow = ['A'].includes(role)
    return (
      <div className="App">
        <Router>
          <Switch>
            {/* All */}
            <ProtectedRoute allow={true} path="/KPIDetail/:emp_code" token={this.props.userToken} component={ViewEmployeeDatail} />
            {/* employee */}
            <ProtectedRoute allow={empRouteAllow} path="/detail/:emp_code" token={this.props.userToken} component={Detail} />
            <ProtectedRoute allow={empRouteAllow} path="/viewselfevaluationform/:emp_code" token={this.props.userToken} component={View} />
            <ProtectedRoute allow={empRouteAllow} path="/selfevaluationform/:year/:quarter/:emp_code" token={this.props.userToken} component={SelfEvaluation} />
            <ProtectedRoute allow={empRouteAllow} path="/KPI/:year/:quarter/:emp_code" token={this.props.userToken} component={EmailSendToEmployeeView} />
            <ProtectedRoute allow={empRouteAllow} path="/annualSelfAssessment/:year" token={this.props.userToken} component={AnnualSelfAssessment} />
            {/* admin & 'DRA' & 'HEAD' & 'HR' */}
            <ProtectedRoute allow={draRouteAllow} exact path="/EmployeeView/:emp_code" token={this.props.userToken} component={EmployeeView} />
            <ProtectedRoute allow={draRouteAllow} exact path="/Insert/:emp_code" token={this.props.userToken} component={InsertEmployeeDetail} />
            <ProtectedRoute allow={draRouteAllow} exact path="/list" token={this.props.userToken} component={PersonalData} />
            {/* admin & Head */}
            <ProtectedRoute allow={adminHeadRouteAllow} path="/annualSelfAssessmentView/:emp_code" token={this.props.userToken} component={AnnualSelfAssessmentVIew} />
            {/* admin */}
            <ProtectedRoute allow={adminRouteAllow} path="/editEmployee/:emp_code" token={this.props.userToken} component={EditEmployee} />
            <ProtectedRoute allow={adminRouteAllow} path="/edit" token={this.props.userToken} component={ListEditEmployee} />
            <ProtectedRoute allow={adminRouteAllow} path="/delete" token={this.props.userToken} component={Delete} />
            <ProtectedRoute allow={adminRouteAllow} path="/selfevaluation" token={this.props.userToken} component={ViewSelfEvaluationMAilSend} />
            <ProtectedRoute allow={adminRouteAllow} path="/add" token={this.props.userToken} component={AddNewEmployee} />
            <ProtectedRoute allow={adminRouteAllow} path="/send" token={this.props.userToken} component={ViewEmailSend} />

            {/* <ProtectedRoute path="/login" token={this.props.userToken} component={EmployeeLogin} /> */}
            <Route exact path="/" component={Login} />
            <Route path='404' component={Error404} />
            <Route path='*' component={Error404} />

          </Switch>
        </Router>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userToken: state.data.userToken,
    userData: state.data.userData,
  }
}

export default connect(mapStateToProps, null)(App);
// export default App;

