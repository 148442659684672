/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { personalData, viewPersonalData, sendemail } from '../../store/actions/dataAction';
import './Data.css';

class ViewEmployeeDetail extends Component {
    state = {
        viewPersonalData: {
            id: '',
            emp_id: '',
            emp_code: '',
            emp_name: '',
            human_resource: '[]',
            hr_performance: 0,
            direct_manager_evaluation: '[]',
            dra_performance: 0,
            department_head_evaluation: '[]',
            dhe_performance: 0,
            overall_performance: 0,
            year: '',
            quarter: '',
        },
        userData: this.props.userData,
    }

    componentDidMount = () => {
        if (this.props.location.viewPersonalData) {
            this.setState({ viewPersonalData: this.props.location.viewPersonalData }, () => {
            })
        }
        else
            this.props.history.goBack()
    }

    // SIDE MENU
    navigateToList = () => {
        this.props.history.push({ pathname: `/list` })
    }

    navigateToDetail = () => {
        this.props.history.push({ pathname: `/detail/${this.state.userData.emp_code}` })
    }
    // END

    render() {
        const { viewPersonalData } = this.state
        return (

            <div>
                {/* ================================================ SIDE MENU ============================================================ */}
                {/* <div className="nav-side-menu">
                    <div className="brand">DASHBOARD</div>
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                    <div className="menu-list">

                        <ul id="menu-content" className="menu-content collapse out">

                            {this.state.userData.role === "EMP" &&
                                <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                    <a onClick={() => this.navigateToDetail()}> Detail </a>
                                </li>
                            }

                            {this.state.userData.role !== "EMP" &&
                                <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                    <a onClick={() => this.navigateToList()}> List of Employees </a>
                                </li>
                            }

                        </ul>
                    </div>
                </div> */}

                <div className="container home">
                    <div className="hr"></div>
                    <h1 id='title'><b><u>{viewPersonalData.emp_name} KPI Detail</u></b></h1>
                    <div id="p_Data" className='col-12'>
                        <div className="col-8">
                            <div>Employee Code: <b> {viewPersonalData.emp_code} </b></div>
                            <div>Name: <b>{viewPersonalData.emp_name} </b></div>
                            <div>Duration: <b>{viewPersonalData.quarter} </b></div>
                            <div>Year: <b>{viewPersonalData.year} </b></div>
                            <div>Employment Status: <b>{viewPersonalData.emp_status} </b></div>
                            <div>Department: <b>{viewPersonalData.department} </b></div>
                            {/* STATIC MATERIAL FOR TESTING */}
                            
                        </div>
                    </div>

                    <div className="hr"></div>

                    <h3 id='title'><b>Criteria</b></h3>
                    <div id='container' className='col-12'>
                        <div className="row">
                            <div className="col-sm-8">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="bg-warning text-center">
                                            <th colSpan="2">
                                                Employee Monthly Evaluation Criteria
                                    </th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">1</td>
                                            <td className="text-left">Human Resource  </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">2</td>
                                            <td className="text-left">Direct Manager</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">3</td>
                                            <td className="text-left">Department Head</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-sm-4">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="bg-warning text-center">
                                            <th colSpan="2">
                                                Bonus Break up (4 Months)
                                    </th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">HR (Attendance)</td>
                                            <td className="text-left">10%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">DM (Individual Performance)</td>
                                            <td className="text-left">10%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Dept. Head</td>
                                            <td className="text-left">5%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right"><b>Overall</b></td>
                                            <td className="text-right"><b>25%</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="hr"></div>

                    {/*============================================== PARAMETERS FOR PERFORMANCE ============================================================  */}
                    <h3 id='title'><b>Parameters for Performance</b></h3>
                    <div id='container' className='col-12'>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            {/* <td className="text-right">1- </td> */}
                                            <td className="text-left">100% consider as <b>Exceptional</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">2- </td> */}
                                            <td className="text-left">80% consider as <b>Exceeds Expectations</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">3- </td> */}
                                            <td className="text-left">60% consider as <b>Successfully Achieved</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">4- </td> */}
                                            <td className="text-left">40% consider as <b>Below Expectations</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">5- </td> */}
                                            <td className="text-left">20% consider as <b>Needs Action</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="hr"></div>

                    {/*============================================== HR ============================================================  */}
                    <h3 id='title'><b>Human Resource Evaluation</b></h3>
                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Sumary</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Performance</th>
                            </tr>
                        </thead>

                        <tbody>
                            {JSON.parse(viewPersonalData.human_resource).map((item, index) => (
                                <tr key={index}>
                                    <th scope="col"> {index + 1} </th>
                                    <th scope="col">{item.particulars}</th>
                                    <th scope="col"> {item.summary}</th>
                                    <th scope="col"> {item.remark} </th>
                                    <th scope="col"> {`${index !== 3 ? item.performance+"%" : ""}`}</th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table id='overall'>
                        <tbody>
                            <tr className="float-right">
                                <th className="col">{` overall Attendance Avg.   ${JSON.parse(viewPersonalData.hr_performance).toFixed(2)}%`} </th>
                            </tr>
                        </tbody>
                    </table>

                    <div className="hr"></div>

                    {/* ===================================== DRA ============================================================== */}
                    <h3 id='title'>Direct Manager Evaluation</h3>
                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Performance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {JSON.parse(viewPersonalData.direct_manager_evaluation).map((item, index) => (
                                <tr key={index}>
                                    <th scope="col"> {index + 1} </th>
                                    <th scope="col"> {item.particulars} </th>
                                    <th scope="col">{item.remark}</th>
                                    <th scope="col">{item.performance}%</th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table id='overall'>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="float-right">
                                <th className="col">{` overall Performance Avg.   ${JSON.parse(viewPersonalData.dra_performance).toFixed(2)}%`} </th>
                            </tr>
                        </tbody>
                    </table>

                    <div className="hr"></div>

                    {/* ================================================ DHE ============================================= */}

                    <h3 id='title'><b>Depart Head Evaluation</b></h3>
                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Performance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {JSON.parse(viewPersonalData.department_head_evaluation).map((item, index) => (
                                <tr key={index}>
                                    <th scope="col"> {index + 1} </th>
                                    <th scope="col"> {item.particulars} </th>
                                    <th scope="col"> {item.remark}</th>
                                    <th scope="col">{JSON.parse(viewPersonalData.dhe_performance).toFixed(2)}%</th>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="hr"></div>

                    {/* ========================================= OVERALL =================================================== */}

                    <h3 id='title'><b><u>Overall Performance</u></b></h3>
                    {/* <div id='container' className='col-12'>
                        <div className="row">
                            <div className="col-sm-8">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="text-right">1</td>
                                            <td className="text-left">Human Resource : <b> Usama Bin fareed </b> </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">2</td>
                                            <td className="text-left">Direct Manager : <b> {viewPersonalData.DRAName} </b> </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">3</td>
                                            <td className="text-left">Department Head : <b> {viewPersonalData.head_departments} </b> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-sm-4">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td className="text-right">HR (Attendance)</td>
                                            <td className="text-left"> {JSON.parse(viewPersonalData.hr_performance).toFixed(2)} %</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">DM (Individual Performance)</td>
                                            <td className="text-left">  {JSON.parse(viewPersonalData.dra_performance).toFixed(2)}%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Dept. Head</td>
                                            <td className="text-left">  {JSON.parse(viewPersonalData.dhe_performance).toFixed(2)}%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right"><b>Overall</b></td>
                                            <td className="text-right"><b> {JSON.parse(viewPersonalData.overall_performance).toFixed(2)}%</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                    <div className="hr"></div>
                    <div id='container' className='col-12'>
                        <div className="row">
                            <div className="col-sm-7">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="bg-warning text-center">
                                            <th colSpan="2">
                                                Overall Evaluation Criteria
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">1</td>
                                            <td className="text-left">Human Resource : <b> Usama Bin Fareed </b>  </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">2</td>
                                            <td className="text-left">Direct Manager : <b> {viewPersonalData.DRAName} </b> </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">3</td>
                                            <td className="text-left">Department Head : <b> {viewPersonalData.head_departments} </b> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-sm-5">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="bg-warning text-center">
                                            <th colSpan="2">
                                                Bonus Break up (4 Months)
                                    </th>
                                        </tr>
                                        <tr>
                                            <td className="text-right">HR (Attendance)</td>
                                            <td className="text-left">{JSON.parse(viewPersonalData.hr_performance).toFixed(2)} %</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">DM (Individual Performance)</td>
                                            <td className="text-left">{JSON.parse(viewPersonalData.dra_performance).toFixed(2)}%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">Dept. Head</td>
                                            <td className="text-left">{JSON.parse(viewPersonalData.dhe_performance).toFixed(2)}%</td>
                                        </tr>
                                        <tr>
                                            <td className="text-right"><b>Overall</b></td>
                                            <td className="text-left"><b>{JSON.parse(viewPersonalData.overall_performance).toFixed(2)}%</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="hr"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPersonalData: state.data.viewPersonalData,
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        sendemail: (props) => dispatch(sendemail(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewEmployeeDetail);