/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './Data.css'
import { personalData, viewPersonalData, onSetViewPersonalData, sendemail } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
import ViewEmployeeDetail from './ViewEmployeeDetail';
import ClipLoader from "react-spinners/ClipLoader";



class ViewEmailSend extends Component {
    // _isMounted = false;
    quarter = ["JAN-JUNE", "JULY-DECEMBER"];

    getCurrentYear = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    
        // Determine the quarter based on the current month
        const currentQuarter = Math.ceil(currentMonth / 3);
        // If it's the first quarter, subtract 1 from the current year
        const year = currentQuarter === 1 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    
        return year;
    }

    state = {
        loader: false,
        personalData: this.props.getPersonalData,
        filterPersonalData: this.props.getPersonalData,
        viewPersonalData: [],
        filterviewPersonalData: [],
        checkedAll: false,
        sendEmail: [],
        selectedQuarter: { 'quarter': null },
        currentYear: this.getCurrentYear(),
        // currentYear: new Date().getFullYear(),
    }

    componentDidMount = () => {
        // this._isMounted = true;
        if (this.state.personalData !== null) {
            this.props.personalData(this.props.userData.emp_code).then(data => {
                // if (this._isMounted) {
                this.setState({ personalData: data, filterPersonalData: data })
                // }
            });
        } else {
            this.props.history.replace({ pathname: `/list` })
        }
    }

    // SIDE MENU START

    navigateToList = () => {
        this.props.history.push({ pathname: `/list` })
    }

    navigateToAdd = () => {
        this.props.history.push({ pathname: '/add' })
    }

    navigateToSelfEvaluation = () => {
        this.props.history.push({ pathname: `/selfevaluation` })
    }

    navigateToEdit = () => {
        this.props.history.push({ pathname: '/edit' })
    }

    navigateToDelete = () => {
        this.props.history.push({ pathname: `/delete` })
    }

    // END

    // componentWillUnmount() {
    //     this._isMounted = false;
    // }

    quarter_change = (event, isYear) => {
        let temp = { ...this.state.selectedQuarter }
        temp.quarter = event.target.value
        this.setState({ selectedQuarter: temp })

    }

    onInputChange = (event) => {
        this.setState({ filterPersonalData: this.state.personalData.filter((names) => { return (names.name.toLowerCase()).includes(event.target.value.toLowerCase()) }) })
    }

    checkAll = () => {
        let temp = [...this.state.filterPersonalData]
        let temp1 = [...this.state.personalData]
        temp.map(singleData => {
            singleData.checkedIndividual = !this.state.checkedAll ? true : false
            temp1[temp1.findIndex(i => singleData.id === i.id)].checkedIndividual = singleData.checkedIndividual
        })
        this.setState({ filterPersonalData: temp, personalData: temp1, checkedAll: !this.state.checkedAll })
    }

    checkedIndividual = (index) => {
        let temp = [...this.state.filterPersonalData]
        let temp1 = [...this.state.personalData]
        temp[index].checkedIndividual = temp[index].checkedIndividual ? !temp[index].checkedIndividual : true
        temp1[temp1.findIndex(i => temp[index].id === i.id)].checkedIndividual = temp[index].checkedIndividual
        this.setState({ filterPersonalData: temp, personalData: temp1 })
    }


    sendMail = () => {
        if (this.state.selectedQuarter.quarter === null) {
            return alert('Please Select Quarter')
        }
        this.setState({ loader: true })
        // this.setState({ sendEmail: this.beforeEmailSend() })
        data = [{ year: this.state.currentYear, quarter: this.state.selectedQuarter.quarter }]
        let data = [...data, this.state.personalData.filter(single => { return single.checkedIndividual })]
        this.props.sendemail(data).then((_data) => {
            this.setState({ loader: false })
            if (_data.err)
                alert("Alert", _data.err)
            // else
            // this.props.history.push({ pathname: `/list` })

        });
    };

    render() {
        return (
            <div>

                {/* =========================================== SIDE MENU ======================================================= */}
                {/* <div className="nav-side-menu">
                    <div className="brand">Dashboard</div>
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                    <div className="menu-list">

                        <ul id="menu-content" className="menu-content collapse out">

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToList()} > List of Employees </a>
                            </li>

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                <a onClick={() => this.navigateToSelfEvaluation()} > Send Self Evaluation Form </a>
                            </li>

                            <li data-toggle="collapse" data-target="#products" className="collapsed dash active">
                                <a onClick={() => this.navigateToEmail()} > Send Email KPI </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.navigateToAdd()}> Add New Employee </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.navigateToEdit()}> Edit Employee </a>
                            </li>

                            <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                <a onClick={() => this.navigateToDelete()}> Delete Employee </a>
                            </li>

                        </ul>
                    </div>
                </div> */}

                {/* =========================================== SEND EMAIL ======================================================= */}
                <div className="container home">
                    <div className="hr"></div>
                    <h1 id='title'><b><u>EMPLOYEES</u></b></h1>
                    <div id="p_Data" className='col-12'>
                        <div className="col-8">
                            <div> Year: <b> {this.state.currentYear} </b></div>
                            <div className="col col-4 quarter">
                                <select onChange={(event) => this.quarter_change(event, false)} className="browser-default custom-select" required>Select Quarter:
                                    <option defaultValue="selected">Select Quarter</option>
                                    {this.quarter.map((item, index) => (
                                        <option key={index} value={item}> {item} </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="hr"></div>

                    <div className="col col-12" style={{ textAlign: 'center' }}>
                        <input type="text" className="hr" style={{ borderRadius: 5, borderWidth: 3 }} size="120" placeholder={'Search'} onChange={(event) => this.onInputChange(event)} />
                    </div>

                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Emp Code</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                {/* <th scope="col">Status</th> */}
                                <th scope="col">
                                    {/* <div><button onClick={() => this.sendMail()} className="col btn btn-primary btn-sm">SEND EMAIL </button></div> */}
                                    <div> Check All <input className="hr" onChange={() => this.checkAll()} name="defaultUnchecked" type="checkbox" checked={this.state.checkedAll} /></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.filterPersonalData.map((data, index) => (
                                <tr key={index}>
                                    <th>{data.emp_code}</th>
                                    <td>{data.name}</td>
                                    <td>{data.email}</td>
                                    {/* <td>{`status`}</td> */}
                                    <td>
                                        <input onChange={() => this.checkedIndividual(index)} name="defaultUnchecked" type="checkbox" checked={data.checkedIndividual} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* ========================================= BUTTON ==================================================== */}
                <div className="hr"></div>
                <div id="button">
                    {!this.state.loader ?
                        <button className="btn btn-primary px-5 mx-10" onClick={() => this.sendMail()}>SEND EMAIL</button>
                        : <div style={{ marginTop: 15 }}>
                            <ClipLoader
                                // css={override}
                                size={40}
                                color="#123abc"
                                loading={true} />
                        </div>
                    }
                </div>

                <div className="hr"></div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.data.userData,
        getPersonalData: state.data.personalData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        ViewEmployeeDetail: (props) => dispatch(ViewEmployeeDetail(props)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        onSetViewPersonalData: (props) => dispatch(onSetViewPersonalData(props)),
        sendemail: (props) => dispatch(sendemail(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewEmailSend);

// export default ViewEmailSend;