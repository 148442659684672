import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { checkAnnualSelfCreated, insertAnnualSelfAssessment } from '../../store/actions/dataAction';

const ASAform = [
    {
        ques: 'How would you rate your performance in 2023?',
        value: '',
        type: 'select',
        options: ['Exceptional', 'Above average', 'Satisfactory', 'Below expectations'],
    },
    {
        ques: 'What is your Expected salary raise (%)?',
        value: '',
        type: 'select',
        options: ['5% to 10%', '10% to 15%', '15% to 20%', '20% to 25%']
    },
    {
        ques: 'Could you please explain why you deserve the anticipated pay increase?',
        value: '',
        type: 'text',
    },
    {
        ques: 'What tasks/targets/goals have you accomplished this year? (mention any 3)',
        value: '',
        type: 'text',
    },
    {
        ques: 'Are you satisfied with your current position?',
        value: '',
        type: 'select',
        options: ['Yes', 'No', 'Not fully satisfied', 'Other'],
        other:''
    },
    {
        ques: 'Do you consider your manager to be nice and supportive?',
        value: '',
        type: 'select',
        options: ['Yes supportive & friendly', 'Not friendly & supportive', 'Depends upon work situation', 'Depends upon mood']
    },
    {
        ques: 'What are your greatest professional strengths?',
        value: '',
        type: 'text',
    },
    {
        ques: 'What changes do you think increase your work performance?',
        value: '',
        type: 'select',
        options: ['Your attitude', 'Your punctuality', 'Your efficiency', 'Sincerity and loyalty']
    },
    {
        ques: 'Things that make you joyful / sad in working environment?',
        value: '',
        type: 'select',
        options: ['I feel happiest at work when I can challenge myself professionally.',
            'Under appreciated',
            'I am most satisfied when I can help my colleagues and other team-workers',
            'Workload']
    },
    {
        ques: 'Do you believe that the work you do is productive and efficient?',
        value: '',
        type: 'select',
        options: ['Yes', 'No', 'Sometime', 'Always']
    },
    {
        ques: 'What challenges are you encountering to achieve the task/Goals?',
        value: '',
        type: 'text',
    },
    {
        ques: 'How\'s your communication with your HR?',
        value: '',
        type: 'select',
        options: ['Good & Friendly', 'Bad or never get a response', 'Controversial', 'Responsive']
    },
    {
        ques: 'If you get an opportunity from another company, what will you do?',
        value: '',
        type: 'select',
        options: ['Embrace the new opportunity with confidence.',
            'Stay committed to working here, appreciating the positive environment and excellent team.',
            'Consider taking a short break from this company for a few months.',
            'Discuss the offer with the current Manager and negotiate for better terms to stay']
    },
    {
        ques: 'Punctuality and attendance:',
        value: '',
        type: 'select',
        options: ['On Time', 'Slight Late', 'Flexible', 'Always late']
    },
    {
        ques: 'In our opinion, company’s environment and policies are:',
        value: '',
        type: 'select',
        options: ['Exceptional', 'Satisfactory', 'Not up to expectations', 'Dissatisfactory']
    },
    {
        ques: 'Share your thoughts and experience regarding NOLIN BPO SERVICES:',
        value: '',
        type: 'text',
    },
]

const AnnualSelfAssessment = ({ userData, insertAnnualSelfAssessment, checkAnnualSelfCreated, match }) => {

    const [formValues, setFormValues] = useState([...ASAform]);
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // Construct the new route with the updated year parameter
        const newPath = `/annualSelfAssessment/${getCurrentYear()}`;
        history.replace(newPath);
        checkData();
    }, []);

    const checkData = () =>{
        
        setLoader('init')
        const dataReq = {
            year: getCurrentYear(), emp_code: userData?.emp_code
        }
        checkAnnualSelfCreated(dataReq).then(res => {
            if (res.err) {
                alert("Alert", res.err)
                setLoader(false)
            }
            else {
                (res.length > 0 && res?.[0]?.hasCreated) && setIsCreated(true)
                setLoader(false)
            }

        })
    }

    const getCurrentYear = () => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1

        // Determine the quarter based on the current month
        const currentQuarter = Math.ceil(currentMonth / 3);
        // If it's the first quarter, subtract 1 from the current year
        const year = currentQuarter === 1 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

        return year;
    }

    const handleInputChange = (index, newValue) => {
        const updatedFormValues = [...formValues];
        updatedFormValues[index].value = newValue;
        setFormValues(updatedFormValues);
        setShowError(false)
    };

    const handleRadioChange = (questionIndex, optionIndex) => {
        const updatedFormValues = [...formValues];
        updatedFormValues[questionIndex].value = ASAform[questionIndex].options[optionIndex];
        setFormValues(updatedFormValues);
        setShowError(false)
    };

    const handleOtherInputChange = (e, questionIndex) => {
        const updatedFormValues = [...formValues];
        updatedFormValues[questionIndex].other = e.target.value;
        setFormValues(updatedFormValues);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Add your validation logic here
        const isValid = validateForm();

        if (isValid) {
            // Handle successful form submission
            setLoader('form')
            const dataReq = {
                assessment: JSON.stringify(formValues), year: getCurrentYear(), emp_code: userData?.emp_code
            }
            insertAnnualSelfAssessment(dataReq).then(res => {
                if (res.err) {
                    alert("Alert", res.err)
                    setLoader(false)
                }
                else {
                    alert("Successfully, added Annual Self Assessment")
                    setLoader(false)
                    checkData()
                    // history.replace({ pathname: `/annualSelfAssessment/2023` })
                }

            })
        } else {
            // Show error message
            setShowError(true);
        }
    };


    const validateForm = () => {
        return formValues.filter((question) => !question.value.trim()).length === 0;
    };
    return (

        <div className="mt-4 pb-4" style={{ marginLeft: 205, width: '85%' }}>
            <div className="card">
                <div className="card-header text-center">
                    <h5 className="card-title mb-0">Annual Self Assessment - {match.params.year}</h5>
                </div>
                <div className="card-body p-0">
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <th scope="row">Employee Code</th>
                                <td>{userData?.emp_code}</td>
                                <th scope="row">Name</th>
                                <td>{userData?.name}</td>
                            </tr>
                            <tr>
                                <th scope="row">Department</th>
                                <td>{userData?.department}</td>
                                <th scope="row">Designation</th>
                                <td>{userData?.designation}</td>
                            </tr>

                            <tr>
                                <th scope="row">Head of Department</th>
                                <td>{userData?.head_departments}</td>
                                <th scope="row">Year</th>
                                <td>{match.params.year}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {loader === 'init' ? <div className="spinner-border mt-4" role="status">
                <span className="sr-only">Loading...</span>
            </div>
                : isCreated ? <div class="alert alert-success mt-3" role="alert">
                    Your <strong>Annual Assessment for the year 2023 </strong> has been submitted successfully.
                </div> :
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={!loader && handleSubmit}>
                                {ASAform.map((question, index) => (
                                    <div className="mb-3" key={index}>
                                        <label className="form-label" style={{ fontSize: '18px', fontWeight: '600' }}>{index + 1}. {question.ques}</label>
                                        {question.type === 'text' ? (
                                            <div>
                                                <textarea
                                                    className="form-control"
                                                    value={question.value}
                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                    rows={Math.max(2, question.value.split('\n').length)} // Set a minimum of 2 rows
                                                    style={{ resize: 'none', overflowY: 'hidden' }} // Disable the textarea's resize handle
                                                />
                                                <hr />
                                            </div>
                                        ) : question.type === 'select' ? (
                                            <div>
                                                {question.options.map((option, optionIndex) => (
                                                    <div className="form-check ml-2 mb-1" key={optionIndex}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id={`radio-${index}-${optionIndex}`}
                                                            name={`radio-${index}`}
                                                            value={option}
                                                            checked={question.value === option}
                                                            onChange={() => handleRadioChange(index, optionIndex)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`radio-${index}-${optionIndex}`}>
                                                            {option}
                                                        </label>
                                                    </div>
                                                ))}
                                                {question.value === 'Other' && (
                                                    <div className="form-group ml-2 mb-1">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id={`other-input-${index}`}
                                                            value={question.other}
                                                            onChange={(e) => handleOtherInputChange(e, index)}
                                                        />
                                                    </div>
                                                )}
                                                <hr />
                                            </div>
                                        ) : null}

                                    </div>
                                ))}
                                {/* Error message */}
                                {showError && (
                                    <div className="alert alert-danger mt-3" role="alert">
                                        Please fill in all required fields.
                                    </div>
                                )}
                                <button type="submit" className="btn btn-primary mt-4 float-right btn-lg" style={{ width: 250 }}>
                                    {loader === 'form' ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> :
                                        'Submit'}
                                </button>
                            </form>

                        </div>
                    </div>}
        </div>

    )
}

const mapStateToProps = state => {
    return {
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        insertAnnualSelfAssessment: (props) => dispatch(insertAnnualSelfAssessment(props)),
        checkAnnualSelfCreated: (props) => dispatch(checkAnnualSelfCreated(props)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AnnualSelfAssessment);
