/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { personalData, viewPersonalData, sendemail, sendEmailEmployee, employeeLogin, setUserToken, onLogout } from '../../store/actions/dataAction';
import './Data.css';

class EmailSendToEmployeeView extends Component {
    state = {
        viewPersonalData: {
            id: '',
            emp_id: '',
            emp_code: '',
            emp_name: '',
            human_resource: '[]',
            hr_performance: '',
            direct_manager_evaluation: '[]',
            dra_performance: '',
            department_head_evaluation: '[]',
            dhe_performance: '',
            overall_performance: '',
            year: '',
            quarter: '',
        },
        loading: true,
    }



    componentDidMount = () => {
        if (this.props.match.params.emp_code === this.props.userData.emp_code.toString()) {
            // if (this.props.employeeLogin) {
            let data = { emp_code: this.props.match.params.emp_code, year: this.props.match.params.year, quarter: this.props.match.params.quarter }
            this.props.sendEmailEmployee(data).then((_data) => {
                if (_data[0].dhe_performance !== 0 && _data[0].dra_performance !== 0 && _data[0].hr_performance !== 0) {
                    this.setState({ viewPersonalData: _data[0], loading: false })
                } else {
                    this.props.history.push({ pathname: `/404` })
                }
            })
            // }
        }
        else
            this.props.history.goBack()
    }

    logout = () => {
        this.props.onLogout()
        this.props.history.push({ pathname: '/' })
    }

    render() {
        const { viewPersonalData, loading } = this.state

        return (
            <div>
                {!loading && <div>
                    {/* ================================================ SIDE MENU ============================================================ */}
                    {/* <div className="nav-side-menu">
                        <div className="brand">DASHBOARD</div>
                        <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                        <div className="menu-list">

                            <ul id="menu-content" className="menu-content collapse out">

                                <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                                    <a onClick={() => this.logout()}> Logout </a>
                                </li>

                            </ul>
                        </div>
                    </div> */}

                    <div className="container home">
                        <div className="hr"></div>
                        <h1 id='title'><b><u>KPI Detail</u></b></h1>
                        <div id="p_Data" className='col-12'>
                            <div className="col-8">
                                <div>Employee Code: <b> {viewPersonalData.emp_code} </b></div>
                                <div>Name: <b>{viewPersonalData.emp_name} </b></div>
                                <div>Quarter: <b>{viewPersonalData.quarter} </b></div>
                            </div>
                        </div>

                        <div className="hr"></div>

                        <h3 id='title'><b>Criteria</b></h3>
                        <div id='container' className='col-12'>
                            <div className="row">
                                <div className="col-sm-8">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr className="bg-warning text-center">
                                                <th colSpan="2">
                                                    Employee Monthly Evaluation Criteria:
                                    </th>
                                            </tr>
                                            <tr>
                                                <td className="text-right">1</td>
                                                <td className="text-left">Human Resource </td>
                                            </tr>
                                            <tr>
                                                <td className="text-right">2</td>
                                                <td className="text-left">Direct Manager</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right">3</td>
                                                <td className="text-left">Department Head</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-4">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr className="bg-warning text-center">
                                                <th colSpan="2">
                                                    Bonus Break up (4 Months)
                                    </th>
                                            </tr>
                                            <tr>
                                                <td className="text-right">HR (Attendance)</td>
                                                <td className="text-left">10%</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right">DM (Individual Performance)</td>
                                                <td className="text-left">10%</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right">Dept. Head</td>
                                                <td className="text-left">5%</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right"><b>Overall</b></td>
                                                <td className="text-right"><b>25%</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="hr"></div>

                        {/*============================================== HR ============================================================  */}
                        <h3 id='title'><b>Parameters for Performance</b></h3>
                        <div id='container' className='col-12'>
                            <div className="row">
                                <div className="col-sm-12">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                {/* <td className="text-right">1- </td> */}
                                                <td className="text-left">100% consider as <b>Exceptional</b></td>
                                            </tr>
                                            <tr>
                                                {/* <td className="text-right">2- </td> */}
                                                <td className="text-left">80% consider as <b>Exceeds Expectations</b></td>
                                            </tr>
                                            <tr>
                                                {/* <td className="text-right">3- </td> */}
                                                <td className="text-left">60% consider as <b>Successfully Achieved</b></td>
                                            </tr>
                                            <tr>
                                                {/* <td className="text-right">4- </td> */}
                                                <td className="text-left">40% consider as <b>Below Expectations</b></td>
                                            </tr>
                                            <tr>
                                                {/* <td className="text-right">5- </td> */}
                                                <td className="text-left">20% consider as <b>Needs Action</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="hr"></div>

                        <h3 id='title'><b>Human Resource Evaluation</b></h3>
                        <table id='employee' className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Particulars</th>
                                    <th scope="col">Sumary</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Performance</th>
                                </tr>
                            </thead>

                            <tbody>
                                {JSON.parse(viewPersonalData.human_resource).map((item, index) => (
                                    <tr key={index}>
                                        <th scope="col"> {index + 1} </th>
                                        <th scope="col">{item.particulars}</th>
                                        <th scope="col"> {item.summary}</th>
                                        <th scope="col"> {item.remark} </th>
                                        <th scope="col"> {`${index !== 3 ? item.performance + "%" : ""}`} </th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table id='overall'>
                            <tbody>
                                <tr className="float-right">
                                    <th className="col">{` overall Attendance Avg.   ${(viewPersonalData.hr_performance)}%`} </th>
                                </tr>
                            </tbody>
                        </table>

                        <div className="hr"></div>

                        {/* ===================================== DRA ============================================================== */}
                        <div className="hr"></div>
                        <h3 id='title'>Direct Manager Evaluation</h3>
                        <table id='employee' className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Particulars</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Performance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {JSON.parse(viewPersonalData.direct_manager_evaluation).map((item, index) => (
                                    <tr key={index}>
                                        <th scope="col"> {index + 1} </th>
                                        <th scope="col"> {item.particulars} </th>
                                        <th scope="col">{item.remark}</th>
                                        <th scope="col">{item.performance}%</th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table id='overall'>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="float-right">
                                    <th className="col">{` overall Performance Avg.   ${viewPersonalData.dra_performance}%`} </th>
                                </tr>
                            </tbody>
                        </table>

                        <div className="hr"></div>

                        {/* ================================================ DHE ============================================= */}

                        <h3 id='title'><b>Depart Head Evaluation</b></h3>
                        <table id='employee' className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Particulars</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Performance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {JSON.parse(viewPersonalData.department_head_evaluation).map((item, index) => (
                                    <tr key={index}>
                                        <th scope="col"> {index + 1} </th>
                                        <th scope="col"> {item.particulars} </th>
                                        <th scope="col"> {item.remark}</th>
                                        <th scope="col">{viewPersonalData.dhe_performance}%</th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="hr"></div>

                        {/* ========================================= OVERALL =================================================== */}

                        <h3 id='title'><b>Overall Performance</b></h3>
                        <div id='container' className='col-12'>
                            <div className="row">
                                <div className="col-sm-8">
                                    <table className="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <td className="text-right">1</td>
                                            <td className="text-left">Human Resource : <b> Usama Bin Fareed </b>  </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">2</td>
                                            <td className="text-left">Direct Manager : <b> {viewPersonalData.DRAName} </b> </td>
                                        </tr>
                                        <tr>
                                            <td className="text-right">3</td>
                                            <td className="text-left">Department Head : <b> {viewPersonalData.head_departments} </b> </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-4">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td className="text-right">HR (Attendance)</td>
                                                <td className="text-left"> {viewPersonalData.hr_performance} %</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right">DM (Individual Performance)</td>
                                                <td className="text-left">  {viewPersonalData.dra_performance}%</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right">Dept. Head</td>
                                                <td className="text-left">  {viewPersonalData.dhe_performance}%</td>
                                            </tr>
                                            <tr>
                                                <td className="text-right"><b>Overall</b></td>
                                                <td className="text-right"><b> {viewPersonalData.overall_performance}%</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="hr"></div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPersonalData: state.data.viewPersonalData,
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        sendemail: (props) => dispatch(sendemail(props)),
        sendEmailEmployee: (data, props) => dispatch(sendEmailEmployee(data, props)),
        employeeLogin: (data) => dispatch(employeeLogin(data)),
        setUserToken: (data) => dispatch(setUserToken(data)),
        onLogout: () => dispatch(onLogout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSendToEmployeeView);