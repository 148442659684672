/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { personalData, viewPersonalData, sendemail, viewSelfEvaluationForm, insertSelfEvaluationData } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
import '../HR Evaluation/Data.css';

class View extends Component {
    state = {
        viewSelfEvaluationForm: {
            emp_id: '',
            emp_code: '',
            emp_name: '',
            emp_email: '',
            evaluation: '[]',
            total: 0,
            emp_department: '',
            emp_designation: '',
            emp_DRA: '',
            emp_departmentHead: '',
            emp_role: '',
            quarter: '',
            year: ''
        },
        userData: this.props.userData,
    }

    componentDidMount = () => {
        if (this.props.location.temp)
            this.setState({ viewSelfEvaluationForm: this.props.location.temp })
        else {
            this.props.history.goBack()
            // this.props.history.replace({ pathname: `/detail/${this.props.userData.emp_code}` })
        }
    }

    // SIDE MENU
    navigateToList = () => {
        this.props.history.push({ pathname: `/list` })
    }

    navigateToDetail = () => {
        this.props.history.push({ pathname: `/detail/${this.state.userData.emp_code}` })
    }
    // END

    render() {
        const { viewSelfEvaluationForm } = this.state
        return (
            <div>
                {/* ================================================ SIDE MENU ============================================================ */}
                {/* <div className="nav-side-menu">
                    <div className="brand">DASHBOARD</div>
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

                    <div className="menu-list">

                        <ul id="menu-content" className="menu-content collapse out">

                            {this.state.userData.role === "EMP" &&
                                <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                    <a onClick={() => this.navigateToDetail()}> Detail </a>
                                </li>
                            }

                            {this.state.userData.role !== "EMP" &&
                                <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                                    <a onClick={() => this.navigateToList()}> List of Employees </a>
                                </li>
                            }

                        </ul>
                    </div>
                </div> */}

                <div className="container home">
                    <div className="hr"></div>
                    <div id='title'><h1 id='title'><b><u>Self Evaluation Form</u></b></h1></div>
                    <div id="p_Data" className='col-12'>
                        <div className="col-8">
                            <div>Employee Code: <b> {viewSelfEvaluationForm.emp_code} </b></div>
                            <div>Name: <b>{viewSelfEvaluationForm.emp_name} </b></div>
                            <div>Department: <b> {viewSelfEvaluationForm.emp_department} </b></div>
                            <div>Designation: <b> {viewSelfEvaluationForm.emp_designation} </b></div>
                            {/* <div>DRA: <b> {viewSelfEvaluationForm.DRA} </b></div> */}
                            <div>Head of Department: <b> {viewSelfEvaluationForm.emp_departmentHead} </b></div>
                            <div>Quarter: <b>{viewSelfEvaluationForm.quarter} </b></div>
                            <div>Year: <b>{viewSelfEvaluationForm.year} </b></div>
                        </div>
                    </div>

                    <div className="hr"></div>

                    {/*============================================== PARAMETERS ============================================================  */}
                    <h3 id='title'><b>Parameters for Performance</b></h3>
                    <div id='container' className='col-12'>
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            {/* <td className="text-right">1- </td> */}
                                            <td className="text-left">100% consider as <b>Exceptional</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">2- </td> */}
                                            <td className="text-left">80% consider as <b>Exceeds Expectations</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">3- </td> */}
                                            <td className="text-left">60% consider as <b>Successfully Achieved</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">4- </td> */}
                                            <td className="text-left">40% consider as <b>Below Expectations</b></td>
                                        </tr>
                                        <tr>
                                            {/* <td className="text-right">5- </td> */}
                                            <td className="text-left">20% consider as <b>Needs Action</b></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="hr"></div>

                    {/* ===================================== DRA ============================================================== */}
                    <h3 id='title'>Self Evaluation</h3>
                    <table id='employee' className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Particulars</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Performance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {JSON.parse(viewSelfEvaluationForm.evaluation).map((item, index) => (
                                <tr key={index}>
                                    <th scope="col"> {index + 1} </th>
                                    <th scope="col"> {item.particulars} </th>
                                    <th scope="col">{item.remark}</th>
                                    <th scope="col">{item.performance}%</th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table id='overall'>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="float-right">
                                <th className="col">{` overall Performance Avg.   ${JSON.parse(viewSelfEvaluationForm.total).toFixed(2)}%`} </th>
                            </tr>
                        </tbody>
                    </table>

                    <div className="hr"></div>

                </div>
            </div >

        );
    }
}

const mapStateToProps = state => {
    return {
        viewPersonalData: state.data.viewPersonalData,
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        personalData: (props) => dispatch(personalData(props)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        sendemail: (props) => dispatch(sendemail(props)),
        viewSelfEvaluationForm: (data, props) => dispatch(viewSelfEvaluationForm(data, props)),
        insertSelfEvaluationData: (data) => dispatch(insertSelfEvaluationData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(View);
// export default View;