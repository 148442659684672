// import todoReducer from "./reducers/TodoReducer";
import dataReducer from './reducers/dataReducer';
import uiReducer from './reducers/uiReducer';
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import localForage from "localforage";

const persistConfig = {
  key: 'root',
  storage: localForage,
  whitelist: ['data', 'uiReducer']
}

const appReducer = combineReducers({
  data: dataReducer,
  uiReducer: uiReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGOUT') {
    // for all keys defined in your persistConfig(s)
    localForage.clear();
    // StorageSensitive.removeItem('persist:root')
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)


export default () => {
  let store = createStore(persistedReducer, applyMiddleware(thunk))
  let persistor = persistStore(store)
  return { store, persistor }
}



// const store = () => {
//     return createStore(rootReducer,applyMiddleware(thunk));
// }

// export default store;