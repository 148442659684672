/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './Data.css';
import { add } from '../../store/actions/dataAction'
import { connect } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

class AddNewEmployee extends Component {
  state = {
    loader: false,
    addData:
      { emp_code: '', name: '', email: '', department: '', designation: '', DRA: '' },

    selectedHeadRoleStatus: { 'head': null, 'role': null, 'status': null }


  }


  status = ['Permanant', 'Probationary', 'Trainee', 'Not Applicable']
  head = ['Noman Rao', 'Hasham Ahmed Qazi', 'Fahad Jeyemby']
  role = ['EMP', 'DRA', 'HR', 'HEAD']

  componentDidMount = () => {
    // this.props.history.replace({ pathname: `/list` })
  }

  // // SIDE MENU START
  // navigateToList = () => {
  //   this.props.history.push({ pathname: `/list` })
  // }

  // navigateToEmail = () => {
  //   this.props.history.push({ pathname: `/send` })
  // }

  // navigateToSelfEvaluation = () => {
  //   this.props.history.push({ pathname: `/selfevaluation` })
  // }

  // navigateToEdit = () => {
  //   this.props.history.push({ pathname: '/edit' })
  // }

  // navigateToDelete = () => {
  //   this.props.history.push({ pathname: `/delete` })
  // }
  // // END

  onInputChange = (event, objectName) => {
    let temp = { ...this.state.addData };
    temp[objectName] = event.target.value;
    this.setState({ addData: temp });
  }

  // DROP-DOWN START
  onHeadChange = (event, variableName) => {
    let temp = { ...this.state.selectedHeadRoleStatus }
    temp[variableName] = event.target.value
    this.setState({ selectedHeadRoleStatus: temp, })
  }

  onRoleChange = (event) => {
    let temp = { ...this.state.selectedHeadRoleStatus }
    temp.role = event.target.value
    this.setState({ selectedHeadRoleStatus: temp.role, })
  }

  onStatusChange = (event) => {
    let temp = { ...this.state.selectedHeadRoleStatus }
    temp.status = event.target.value
    this.setState({ selectedHeadRoleStatus: temp.status, })
  }
  // END

  addData = () => {
    this.setState({loader: true})
    let data = {
      emp_code: this.state.addData.emp_code,
      name: this.state.addData.name,
      email: this.state.addData.email,
      department: this.state.addData.department,
      designation: this.state.addData.designation,
      selectedHeadRoleStatus: this.state.selectedHeadRoleStatus,
      DRA: this.state.addData.DRA
    }
    this.props.add(data).then((data) => {
      this.setState({loader: false})
      if (data.err)
        alert('Error, '+ data.err)
      else
        this.props.history.push({ pathname: `/list` })

    })
  }

  render() {
    const { addData } = this.state
    return (
      <div>

        {/* =========================================== SIDE MENU ======================================================= */}
        {/* <div className="nav-side-menu">
          <div className="brand">Dashboard</div>
          <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>

          <div className="menu-list">

            <ul id="menu-content" className="menu-content collapse out">

              <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                <a onClick={() => this.navigateToList()} > List of Employees </a>
              </li>

              <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                <a onClick={() => this.navigateToSelfEvaluation()} > Send Self Evaluation Form </a>
              </li>

              <li data-toggle="collapse" data-target="#products" className="collapsed dash">
                <a onClick={() => this.navigateToEmail()} > Send Email KPI </a>
              </li>

              <li data-toggle="collapse" data-target="#service" className="collapsed dash active">
                <a onClick={() => this.navigateToAdd()}> Add New Employee </a>
              </li>

              <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                <a onClick={() => this.navigateToEdit()}> Edit Employee </a>
              </li>

              <li data-toggle="collapse" data-target="#service" className="collapsed dash">
                <a onClick={() => this.navigateToDelete()}> Delete Employee </a>
              </li>

            </ul>
          </div>
        </div> */}

        {/* ================================================== ADD DATA =========================================================== */}
        <div className="container home">
          <div className="hr"></div>
          <h1 id="title" style={{ fontSize: 26 }}><b><u>Add New Employee</u></b></h1>
          <div className="hr"></div>

          <div className="col col-12">

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee Code:</b></label>
              <input value={addData.emp_code} type="number" className="form-control" aria-describedby="emailHelp" placeholder="Employee Code" size="20" onChange={(event) => this.onInputChange(event, "emp_code")} />
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee Name:</b></label>
              <input value={addData.name} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Name" size="20" onChange={(event) => this.onInputChange(event, "name")} />
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee Email:</b></label>
              <input value={addData.email} type="email" className="form-control" aria-describedby="emailHelp" placeholder="Employee Email" size="20" onChange={(event) => this.onInputChange(event, "email")} />
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee Department:</b></label>
              <input value={addData.department} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Department" size="20" onChange={(event) => this.onInputChange(event, "department")} />
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee Designation:</b></label>
              <input value={addData.designation} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Designation" size="20" onChange={(event) => this.onInputChange(event, "designation")} />
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee DRA:</b></label>
              <input value={addData.DRA} type="number" className="form-control" aria-describedby="emailHelp" placeholder="DRA's EMPLOYEE CODE" size="20" onChange={(event) => this.onInputChange(event, "DRA")} />
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee HOD:</b></label>
              {/* <input value={addData.head_departments} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Head of Department (example: FB, HB, AB)" size="20" onChange={(event) => this.onInputChange(event, "head_departments")} /> */}
              <select onChange={(event) => this.onHeadChange(event, 'head')} className="browser-default custom-select">Select Quarter:
                                <option defaultValue="selected">Select HOD</option>
                {this.head.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee Role:</b></label>
              {/* <input value={addData.role} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Role" size="20" onChange={(event) => this.onInputChange(event, "role")} /> */}
              <select onChange={(event) => this.onHeadChange(event, 'role')} className="browser-default custom-select">Select Quarter:
                                <option defaultValue="selected">Select Role</option>
                {this.role.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label style={{ fontSize: 18 }}><b>Employee Status:</b></label>
              {/* <input value={addData.emp_status} type="text" className="form-control" aria-describedby="emailHelp" placeholder="Employee Status" size="20" onChange={(event) => this.onInputChange(event, "emp_status")} /> */}
              <select onChange={(event) => this.onHeadChange(event, 'status')} className="browser-default custom-select">Select Quarter:
                                <option defaultValue="selected">Select Status</option>
                {this.status.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))}
              </select>
            </div>

          </div>

          {/* ========================================= BUTTON ==================================================== */}
          <div className="hr"></div>
          <div id="button">
          {!this.state.loader ? 
            <button className="btn btn-primary px-5" onClick={() => this.addData()}>SAVE</button>
            :<div style={{ marginTop: 15 }}>
                                <ClipLoader
                                    // css={override}
                                    size={40}
                                    color="#123abc"
                                    loading={true} />
                            </div>
                            }
          </div>

          <div className="hr"></div>

        </div>

      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    add: (data) => dispatch(add(data)),
  }
}

export default connect(null, mapDispatchToProps)(AddNewEmployee);

// export default AddNewEmployee;