import React, { Component } from 'react';
import './login.css';
import { login, onLogout, personalData, changePwd, setUserData, setUserToken, viewPersonalData, onSetViewPersonalData, onEmployeeViewPersonalData, sendCodeEmail } from '../../store/actions/dataAction';
import { connect } from 'react-redux';
import Popup from "reactjs-popup";
import jwtDecode from 'jwt-decode';
import 'bootstrap/dist/css/bootstrap.css';
import ClipLoader from "react-spinners/ClipLoader";

class Login extends Component {
    state = {
        controls: {
            email: {
                value: '',
                valid: false
            },
            password: {
                value: '',
                valid: false
            },
            code: {
                value: '',
                valid: false
            }
        },
        show: false,
        loading: false,
        loader: false,
        isChangePwd: false
    }


    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    componentDidMount = () => {
        if (this.props.userData !== null) {
            if (['EMP', 'DRA', 'HR', 'HEAD'].includes(this.props.userData.role) && (this.props.userData.emp_code !== 1)) {
                let _data = this.props.userData.emp_code
                this.setState({ loader: 'init' })
                // alert("Kindly follow the link which you have provided in Email")
                this.props.onEmployeeViewPersonalData(_data).then(data => {
                    if (this.props.userData.role === 'HEAD' && (this.props.userData.emp_code !== 1)) {
                        this.props.onSetViewPersonalData(data)
                        this.props.history.push({ pathname: `/list` })
                    } else if (data[0] === undefined) {
                        this.props.onLogout()
                        this.props.history.push({ pathname: '/' })
                        this.setState({ loading: false })
                    }
                    else {
                        this.props.onSetViewPersonalData(data)
                        this.props.history.push({ pathname: `/detail/${data[0].emp_code}` })
                    }

                    this.setState({ loading: true, loader: false })

                });
            } else {
                this.props.history.push({ pathname: `/list` })
            }
        }

    }

    onInputValue = (event, type) => {
        let temp = { ...this.state.controls }
        temp[type].value = event.target.value
        this.setState({ controls: temp })
    }

    login = (e) => {
        const data = {
            email: this.state.controls.email.value,
            pwd: this.state.controls.password.value
        }
        this.setState({ loading: true })
        this.props.login(data).then((data) => {
            let setUserData = data;
            if (setUserData.token) {
                const decodedData = jwtDecode(setUserData.token)
                this.props.setUserData(decodedData)
                this.props.setUserToken(setUserData.token)

                if (this.props.history.location.state && this.props.history.location.state.from.pathname.slice(0, 5) === '/KPI/') {
                    let pathRedirect = this.props.history.location.state.from.pathname
                    let pathRedirectSplit = pathRedirect.split('/').pop();
                    if (pathRedirectSplit === this.props.userData.emp_code.toString()) {
                        let _data = this.props.userData.emp_code
                        // alert("Kindly follow the link which you have provided in Email")
                        this.props.onEmployeeViewPersonalData(_data).then(data => {
                            this.props.onSetViewPersonalData(data)
                            this.props.history.push({ pathname: pathRedirect })
                        })
                    } else {
                        alert("Email or Password is incorrect")
                        this.setState({ loading: false })
                    }

                } else
                    if (['EMP', 'DRA', 'HR', 'HEAD'].includes(this.props.userData.role) && (this.props.userData.emp_code !== 1)) {

                        let _data = this.props.userData.emp_code
                        // alert("Kindly follow the link which you have provided in Email")
                        this.props.onEmployeeViewPersonalData(_data).then(data => {
                            if (this.props.userData.role === 'HEAD' && (this.props.userData.emp_code !== 1)) {
                                this.props.onSetViewPersonalData(data)
                                this.props.history.push({ pathname: `/list` })
                            }
                            else if (data[0] === undefined) {
                                this.props.onLogout()
                                this.props.history.push({ pathname: '/' })
                                this.setState({ loading: false })
                            }
                            else {
                                this.props.onSetViewPersonalData(data)
                                this.props.history.push({ pathname: `/detail/${data[0].emp_code}` })
                            }
                            this.setState({ loading: false })

                        });
                    } else {
                        this.props.history.push({ pathname: `/list` })

                    }
            }
            else {
                alert("Email or Password is incorrect")
                this.setState({ loading: false })
            }
        });
    }

    changePwd = (close) => {
        if(!this.state.controls.code.value && !this.state.controls.password.value){
            return alert('Code & Password is required')
        }
        this.setState({ loader: 'email' })

        const data = {
            email: this.state.controls.email.value,
            code: this.state.controls.code.value,
            pwd: this.state.controls.password.value
        }

        this.props.changePwd(data).then((data) => {
            this.setState({ loader: false })

            if (data.err)
                alert("Error, " + data.err)
            else {
                close()
                this.setState({
                    isChangePwd: false, password: {
                        value: '',
                        valid: false
                    },
                })
            }

        })
    }

    sendEmail = () => {
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailRegex = /^[^\s@]+@nolinbpo\.com$/i;
        if (emailRegex.test(this.state.controls.email.value)) {
            this.setState({ loader: 'email' })

            const data = {
                email: this.state.controls.email.value,
            }
            this.props.sendCodeEmail(data).then((data) => {
                this.setState({ loader: false })

                if (data.err)
                    alert("Error, " + data.err)
                else
                    this.setState({ isChangePwd: true })
            })
        } else {
            alert('Invalid email address');
        }
    }

    render() {
        // const { show, handleShow, handleClose } = this.state
        return (
            !(this.state.loader === 'init') ? <div className="loginCont">
                <div className="container">
                    <div className="d-flex justify-content-center h-100">
                        <div className="card">
                            <div className="card-header">
                                <h3>Sign In</h3>
                            </div>

                            <div className="card-body">
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                                    </div>
                                    <input
                                        value={this.state.controls.email.value}
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className="form-control"
                                        aria-describedby="emailHelp"
                                        size="20"
                                        onChange={(event) => this.onInputValue(event, "email")}
                                        onKeyPress={(e) => { e.key === "Enter" && this.login() }}
                                    />

                                </div>

                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-key"></i></span>
                                    </div>
                                    <input
                                        value={this.state.controls.password.value}
                                        type="password"
                                        name="pass"
                                        placeholder="Password"
                                        className="form-control"
                                        aria-describedby="emailHelp"
                                        size="20"
                                        onChange={(event) => this.onInputValue(event, "password")}
                                        onKeyPress={(e) => { e.key === "Enter" && this.login() }}
                                    />
                                </div>
                                {!this.state.loading ?
                                    <button style={{ display: 'flex', margin: '0 auto' }} className="btn btn-primary" size="50" onClick={() => this.login()} >
                                        SIGN IN
                                    </button>
                                    :
                                    <div style={{ marginTop: 30 }}>
                                        <ClipLoader
                                            // css={override}
                                            size={40}
                                            color="#123abc"
                                            loading={true} />
                                    </div>
                                }
                            </div>

                            {/* ===================================================== CHANGE PASSWORD ================================================ */}

                            <div className="card-footer">
                                <div className="d-flex justify-content-center links">
                                    {`Want to Change/re-new your/create new`}
                                    <Popup modal className='my-popup' trigger={<button style={{ background: '#ffffffa8' }} className="btn btn-link">
                                        {`password?`}
                                    </button>}>
                                        {close => (<div className="modalPwd" style={{ color: 'black' }}>
                                            <button className="close" onClick={() => {
                                                close();
                                                this.setState({ isChangePwd: false })
                                            }}>
                                                &times;
                                            </button>
                                            <div className="header"> Change Password </div>
                                            <div className="content">
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email address</label>
                                                    <input type="email" className="form-control" id="email" required
                                                        disabled={this.state.isChangePwd}
                                                        value={this.state.controls.email.value}
                                                        onChange={(event) => this.onInputValue(event, "email")} />
                                                    <div id="passwordHelpBlock" className="form-text">
                                                        {this.state.isChangePwd ?
                                                            'A verification code has been sent to your email. Please check your email and confirm the code to proceed with the password change.'
                                                            : 'A verification code will be sent to your email address.'}
                                                    </div>
                                                </div>
                                                {this.state.isChangePwd && <>
                                                    <div className="mb-3">
                                                        <label htmlFor="code" className="form-label">Verification Code</label>
                                                        <input type="text" className="form-control" id="code" required
                                                            value={this.state.controls.code.value}
                                                            onChange={(event) => this.onInputValue(event, "code")} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="password" className="form-label">Password</label>
                                                        <input type="password" className="form-control" id="password" required
                                                            value={this.state.controls.password.value}
                                                            onChange={(event) => this.onInputValue(event, "password")} />
                                                    </div>
                                                </>}
                                            </div>
                                            <div className="actions">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        this.state.isChangePwd ? this.changePwd(close) : this.sendEmail();
                                                    }}
                                                >
                                                    {this.state.loader === 'email' ? <div className="spinner-border mx-4" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                        this.state.isChangePwd ? 'Change Password' : 'Send Email'}
                                                </button>
                                            </div>

                                            {/* <div className="form-group">
                                                <label style={{ fontSize: 18 }}><b>Change Password:</b></label>
                                                <input
                                                    value={this.state.controls.email.value}
                                                    type="email"
                                                    name="email"
                                                    placeholder="email"
                                                    className="form-control input-small"
                                                    onChange={(event) => this.onInputValue(event, "email")}
                                                />
                                                <div className="hr-modal"></div>
                                                <input
                                                    value={this.state.controls.password.value}
                                                    type="password"
                                                    name="pass"
                                                    placeholder="Password"
                                                    className="form-control input-small"
                                                    onChange={(event) => this.onInputValue(event, "password")}
                                                />
                                            </div> */}


                                        </div>)}
                                    </Popup>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> : <ClipLoader
                // css={override}
                size={40}
                color="#123abc"
                loading={true} />
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.data.userData,
        loading: state.data.isLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(login(data)),
        onLogout: () => dispatch(onLogout()),
        changePwd: (data) => dispatch(changePwd(data)),
        personalData: (props) => dispatch(personalData(props)),
        setUserData: (data) => dispatch(setUserData(data)),
        setUserToken: (data) => dispatch(setUserToken(data)),
        viewPersonalData: (props) => dispatch(viewPersonalData(props)),
        onSetViewPersonalData: (props) => dispatch(onSetViewPersonalData(props)),
        onEmployeeViewPersonalData: (props) => dispatch(onEmployeeViewPersonalData(props)),
        sendCodeEmail: (props) => dispatch(sendCodeEmail(props)),
        // uiStopLoading: (props) => dispatch(uiStopLoading(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

// export default Login;